import { useEffect } from "react";
import { defineMessages, FormattedMessage, FormattedNumber } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Heading, Text } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointment from "lib/appointments/useAppointment.js";
import useConfirmAppointment from "lib/appointments/useConfirmAppointment.js";
import Loading from "components/layout/Loading.js";
import DancersTable from "components/signUp/DancersTable.js";
import ConfirmAppointmentForm from "components/signUp/ConfirmAppointmentForm.js";

const messages = defineMessages({
  title: { id: "sign-up.title.confirm" },
});

function ConfirmForm({ appointment }) {
  const navigate = useNavigate();
  const [confirmAppointment, response] = useConfirmAppointment(appointment);

  useEffect(() => {
    if (response.status === 200) {
      if (response.json.checkoutUrl) {
        window.location = response.json.checkoutUrl;
      } else {
        navigate(`../confirmed/${appointment.appointmentUuid}`);
      }
    }
  }, [navigate, appointment, response]);

  const handleSubmit = ({ name, email, method }) => {
    confirmAppointment(name, email, method);
  };

  return (
    <ConfirmAppointmentForm
      initialValues={{ name: "", email: "", method: "paynow" }}
      onSubmit={handleSubmit}
    />
  );
}

function Confirm() {
  usePageTitle(messages.title);

  const params = useParams();

  const response = useAppointment(params.appointmentUuid);
  if (!response.json) {
    return <Loading />;
  }

  if (response.status !== 200) {
    return <Text>Not found</Text>;
  }

  const { appointment, appointmentBlock } = response.json;

  return (
    <>
      <Heading textAlign="center">
        <FormattedMessage id="sign-up.confirm-appointment-title" />
      </Heading>
      <Text>
        <FormattedMessage id="sign-up.confirm-appointment-instructions" />
      </Text>
      <Heading as="h3" size="md" textAlign="center">
        {appointmentBlock.label}
      </Heading>
      <DancersTable dancers={appointment.dancers} mb="8" />
      <Text>
        <FormattedMessage
          id="sign-up.confirm-appointment-deposit-note"
          values={{
            amount: (
              <FormattedNumber
                value={appointment.deposit}
                style="currency"
                currency="USD"
              />
            ),
          }}
        />
      </Text>
      <Heading as="h2" size="md" textAlign="center">
        <FormattedMessage id="sign-up.confirm-appointment-form-instructions" />
      </Heading>
      <ConfirmForm appointment={appointment} />
    </>
  );
}

export default Confirm;
