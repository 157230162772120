import {
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useSessionOrders from "lib/session/useSessionOrders.js";
import Loading from "components/layout/Loading.js";
import { defineMessages, FormattedNumber } from "react-intl";
import { Link, useParams } from "react-router-dom";

const messages = defineMessages({
  title: { id: "session.title.manage" },
});

function Orders() {
  usePageTitle(messages.title);

  const { sessionUuid } = useParams();
  const orderResponse = useSessionOrders(sessionUuid);

  if (!orderResponse.json) {
    return <Loading />;
  }

  return (
    <>
      <Heading size="lg" textAlign="center">
        Orders
      </Heading>
      <TableContainer mb="10">
        <Table>
          <Thead>
            <Th>Order</Th>
            <Th>Contact</Th>
            <Th>Photos</Th>
            <Th>Status</Th>
            <Th>Items</Th>
            <Th>Deposit</Th>
            <Th>Payment</Th>
          </Thead>
          <Tbody>
            {orderResponse.json.orders.map((order) => (
              <Tr key={order.orderNumber}>
                <Td>
                  <Link
                    to={`/order/${order.appointmentUuid}/${order.orderNumber}`}
                  >
                    {order.orderNumber}
                  </Link>
                </Td>
                <Td>
                  {order.contactName} &lt;{order.contactEmail}&gt;
                </Td>
                <Td>
                  <FormattedNumber value={order.photoCount} />
                </Td>
                <Td>{order.statusName}</Td>
                <Td>
                  <FormattedNumber
                    value={order.itemsTotal}
                    style="currency"
                    currency="USD"
                  />
                </Td>
                <Td>
                  <FormattedNumber
                    value={-order.deposit}
                    style="currency"
                    currency="USD"
                  />
                </Td>
                <Td>
                  <FormattedNumber
                    value={order.itemsTotal - order.deposit}
                    style="currency"
                    currency="USD"
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Orders;
