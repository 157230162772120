import { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import {
  Box,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";

function PhotoBlock({ appointment, items, photo }) {
  const photoItems = useMemo(() => {
    return items.filter((item) => {
      return item.photoFilename === photo.filename;
    });
  }, [items, photo]);

  if (photoItems.length < 1) {
    return null;
  }

  return (
    <Flex py="3" alignItems="center" direction={{ base: "column", sm: "row" }}>
      <Box
        backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
          appointment.appointmentUuid
        )}/${encodeURIComponent(photo.filename)}`}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        height="250px"
        width="250px"
      />
      <Box pt={{ base: "5", sm: "0" }} pl={{ base: "0", sm: "5" }}>
        {photo.title && (
          <Heading as="h3" size="md">
            {photo.title}
          </Heading>
        )}
        {photoItems.map((item) => (
          <Text key={item.sku}>
            <FormattedNumber
              value={item.unitPrice * item.quantity}
              style="currency"
              currency="USD"
            />{" "}
            ({item.quantity}) — {item.name}
          </Text>
        ))}
      </Box>
    </Flex>
  );
}

function NonPhotoItems({ items }) {
  const nonPhotoItems = useMemo(() => {
    return items.filter((item) => {
      return item.photoFilename === null;
    });
  }, [items]);

  if (nonPhotoItems.length < 1) {
    return null;
  }

  return nonPhotoItems.map((item) => (
    <Text
      key={item.sku}
      ml={{ base: "0", sm: "250px" }}
      p={{ base: "0", sm: "5" }}
    >
      <FormattedNumber
        value={item.unitPrice * item.quantity}
        style="currency"
        currency="USD"
      />{" "}
      ({item.quantity}) — {item.name}
    </Text>
  ));
}

function OrderSummary({ appointment, order, items, photos }) {
  const likedPhotos = useMemo(() => {
    const likedPhotos = [];
    if (photos) {
      for (const photo of photos) {
        if (photo.reviewStatus === "like") {
          likedPhotos.push(photo);
        } else {
          for (const item of items) {
            if (
              item.photoFilename === photo.filename ||
              item.photoFilename === photo.filename
            ) {
              likedPhotos.push(photo);
              break;
            }
          }
        }
      }
    }
    return likedPhotos;
  }, [items, photos]);

  return (
    <>
      {likedPhotos.map((photo) => (
        <PhotoBlock
          key={photo.filename}
          appointment={appointment}
          items={items}
          photo={photo}
        />
      ))}
      <NonPhotoItems items={items} />
      <TableContainer mb="10" mx="auto" maxWidth="30em">
        <Table>
          <Tbody>
            <Tr>
              <Td>Items Total</Td>
              <Td>
                <FormattedNumber
                  value={order.itemsTotal}
                  style="currency"
                  currency="USD"
                />
              </Td>
            </Tr>
            {appointment.deposit > 0 && (
              <Tr>
                <Td>Appointment Deposit Credit</Td>
                <Td>
                  (
                  <FormattedNumber
                    value={appointment.deposit}
                    style="currency"
                    currency="USD"
                  />
                  )
                </Td>
              </Tr>
            )}
            <Tr>
              <Td>
                {order.status === "cart" ? "Due at Checkout" : "Total Paid"}
                <br />
                {order.orderId && (
                  <>
                    Payment reference#
                    <br />
                    {order.orderId}
                  </>
                )}
              </Td>
              <Td>
                <FormattedNumber
                  value={Math.max(order.itemsTotal - appointment.deposit, 0)}
                  style="currency"
                  currency="USD"
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default OrderSummary;
