import { useMemo, useState } from "react";
import { defineMessages } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointment from "lib/appointments/useAppointment.js";
import useAppointmentPhotos from "lib/appointments/useAppointmentPhotos.js";
import Loading from "components/layout/Loading.js";
import useReviewPhoto from "../../../../lib/appointments/useReviewPhoto.js";

const messages = defineMessages({
  title: { id: "order.title" },
});

function PhotoBlock({ appointment, photo }) {
  const [reviewStatus, setReviewStatus] = useState(photo.reviewStatus);

  const [review] = useReviewPhoto(appointment, photo.filename);

  const handleChange = (status) => {
    setReviewStatus(status);
    review(status);
  };

  const opacity =
    reviewStatus === "pass" ? "0.25" : reviewStatus === "maybe" ? "0.6" : "1";
  return (
    <Flex py="3" alignItems="center" direction={{ base: "column", sm: "row" }}>
      <Box
        backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
          appointment.appointmentUuid
        )}/${encodeURIComponent(photo.filename)}`}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        height={{ base: "400px", sm: "500px" }}
        width={{ base: "400px", sm: "500px" }}
        opacity={opacity}
      />
      <Box pl={{ base: "0", sm: "5" }}>
        <RadioGroup
          name="reviewStatus"
          size="lg"
          value={reviewStatus}
          onChange={handleChange}
        >
          <Stack>
            <Radio value="like">👍 I’d like this one</Radio>
            <Radio value="maybe">🤔 Not sure yet…</Radio>
            <Radio value="pass">👎 No thanks</Radio>
          </Stack>
        </RadioGroup>
      </Box>
    </Flex>
  );
}

function RatePhotos({ appointment, photos }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("..");
  };

  return (
    <>
      {photos.map((photo) => (
        <PhotoBlock
          key={photo.filename}
          appointment={appointment}
          photo={photo}
        />
      ))}
      <Center>
        <Button type="button" colorScheme="blue" onClick={handleClick}>
          All Done!
        </Button>
      </Center>
    </>
  );
}

function PhotosInstructions({ onClick }) {
  return (
    <Container>
      <Text>
        To begin, select the pictures from your appointment that you like best.
        On the next page, click the “I’d like this one” button next to the
        pictures that you’d like to order.
      </Text>
      <Text>
        <strong>A couple of quick notes:</strong>
      </Text>
      <Text>
        These are the proofs straight from the camera — they haven’t been edited
        yet. But don’t fret: we will crop, straighten, edit out the green tape,
        and extend the background as necessary for any picture you select.
      </Text>
      <Text>
        You’ll see every picture we took. Some will look similar, but this
        allows you to choose the specific pose and facial expression that fits
        your dancer best!
      </Text>
      <Button type="button" colorScheme="blue" onClick={onClick}>
        Got It — Let’s Go!
      </Button>
    </Container>
  );
}

function SelectPictures() {
  usePageTitle(messages.title);

  const params = useParams();

  const appointmentResponse = useAppointment(params.appointmentUuid);
  const photosResponse = useAppointmentPhotos(params.appointmentUuid);

  const [readInstructions, setReadInstructions] = useState(false);
  const showInstructions = useMemo(() => {
    if (readInstructions) {
      return false;
    }
    if (photosResponse.json) {
      for (const photo of photosResponse.json.photos) {
        if (photo.reviewStatus !== "none") {
          return false;
        }
      }
    }
    return true;
  }, [photosResponse.json, readInstructions]);

  if (!appointmentResponse.json || !photosResponse.json) {
    return <Loading />;
  }

  if (appointmentResponse.status !== 200 || photosResponse.status !== 200) {
    return <Text>Not found</Text>;
  }

  const handleInstructionsClick = () => {
    setReadInstructions(true);
  };

  return (
    <>
      <Heading textAlign="center">Select Your Pictures</Heading>
      {showInstructions ? (
        <PhotosInstructions onClick={handleInstructionsClick} />
      ) : (
        <RatePhotos
          appointment={appointmentResponse.json.appointment}
          photos={photosResponse.json.photos}
        />
      )}
    </>
  );
}

export default SelectPictures;
