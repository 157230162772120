import { Fragment, useState } from "react";
import { FormattedMessage, FormattedTime } from "react-intl";
import { DateTime } from "luxon";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import useUpdateAppointmentStatus from "lib/appointments/useUpdateAppointmentStatus.js";

function CheckInButtons({ onCheckInClick, onWalkInClick }) {
  return (
    <>
      <Heading size="3xl" textAlign="center" m="0" py="20">
        Check In Here!
      </Heading>
      <Text textAlign="center">
        <Button
          type="button"
          size="lg"
          colorScheme="blue"
          onClick={onCheckInClick}
        >
          I Have an Appointment
        </Button>
      </Text>
      <Text my="5" textAlign="center">
        <Button type="button" size="md" onClick={onWalkInClick}>
          I Am a Walk-In
        </Button>
      </Text>
    </>
  );
}

function CheckInButton({ appointment }) {
  const [updateStatus, response] = useUpdateAppointmentStatus(appointment);

  const handleAppointmentClick = () => {
    // updateStatus("checkin");
  };

  return (
    <Text fontSize="lg" key={appointment.appointmentUuid} my="5">
      <Button type="button" size="lg" onClick={handleAppointmentClick}>
        {appointment.dancers.map((dancer) => (
          <Fragment key={dancer.name}>
            {dancer.name} -{" "}
            <FormattedMessage
              id="dancer-costumes"
              values={{ count: dancer.costumes }}
            />
            <br />
          </Fragment>
        ))}
        <Text as="span" pl="5" fontSize="xs" color="blackAlpha.600">
          <FormattedTime value={DateTime.fromISO(appointment.date)} />
        </Text>
        {appointment.status === "deppaid" && (
          <Text as="span" pl="5" fontSize="xs" color="black">
            PAID
          </Text>
        )}
      </Button>
    </Text>
  );
}

function CheckInList({ appointments, onAppointmentClick }) {
  return (
    <Box textAlign="center">
      <Heading size="2xl" textAlign="center" m="0" py="10">
        Find Your Name
      </Heading>
      {appointments.map((appointment) => (
        <CheckInButton
          key={appointment.appointmentUuid}
          appointment={appointment}
        />
      ))}
    </Box>
  );
}

function CheckIn({ appointments }) {
  const [mode, setMode] = useState("welcome");

  const handleCheckInClick = () => {
    setMode("checkin");
  };

  const handleWalkInClick = () => {};

  return (
    <Flex direction="column" justifyContent="center" w="60%" h="100vh">
      <Box p="8" maxHeight="100vh" overflowY="scroll">
        <Heading
          as="h1"
          variant="site"
          textAlign="center"
          size="3xl"
          m="0"
          py="6"
        >
          <FormattedMessage id="its-picture-day" />
        </Heading>
        {mode === "welcome" && (
          <CheckInButtons
            onCheckInClick={handleCheckInClick}
            onWalkInClick={handleWalkInClick}
          />
        )}
        {mode === "checkin" && <CheckInList appointments={appointments} />}
      </Box>
    </Flex>
  );
}

export default CheckIn;
