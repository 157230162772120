import { Route, Routes } from "react-router-dom";
import OrderLayout from "components/layout/OrderLayout.js";
import Order from "pages/Order.js";
import AppointmentOrder from "pages/order/appointmentUuid/orderNumber/Order.js";
import SelectPictures from "pages/order/appointmentUuid/orderNumber/SelectPictures.js";
import Package from "pages/order/appointmentUuid/orderNumber/Package.js";
import AddPictures from "pages/order/appointmentUuid/orderNumber/AddPictures.js";
import AddPicturesFilename from "pages/order/appointmentUuid/orderNumber/add-pictures/Filename.js";
import Confirm from "pages/order/appointmentUuid/orderNumber/Confirm.js";
import PackingSlip from "pages/order/appointmentUuid/orderNumber/PackingSlip.js";

function OrderRoutes() {
  return (
    <Routes caseSensitive={true}>
      <Route
        path="/:appointmentUuid/:orderNumber/packing-slip"
        element={<PackingSlip />}
      />
      <Route path="/" element={<OrderLayout />}>
        <Route index={true} element={<Order />} />
        <Route path=":appointmentUuid/:orderNumber">
          <Route index element={<AppointmentOrder />} />
          <Route path="select-pictures" element={<SelectPictures />} />
          <Route path="package" element={<Package />} />
          <Route path="add-pictures">
            <Route index element={<AddPictures />} />
            <Route path=":filename" element={<AddPicturesFilename />} />
          </Route>
          <Route path="confirm" element={<Confirm />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default OrderRoutes;
