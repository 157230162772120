import { setLocale } from "yup";
import { locale, localizedYupMessage } from "./locale.js";
import "./string.js";

setLocale(locale);

// date is specifically excluded here; use our own datetime instead
export {
  mixed,
  string,
  number,
  bool,
  boolean,
  object,
  array,
  ref,
  lazy,
  reach,
  isSchema,
  addMethod,
  setLocale,
  ValidationError,
} from "yup";

export { default as datetime } from "./datetime.js";

export { localizedYupMessage };
