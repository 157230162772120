import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Heading, Text } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointmentBlocks from "lib/appointments/useAppointmentBlocks.js";
import useCreateAppointment from "lib/appointments/useCreateAppointment.js";
import Loading from "components/layout/Loading.js";
import AppointmentBlockLabel from "components/appointment/AppointmentBlockLabel.js";
import AddDancersForm from "components/signUp/AddDancersForm.js";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: { id: "sign-up.title.appointment" },
});

const MAX_DANCERS = 6;

const initialValues = {};
for (let i = 1; i <= MAX_DANCERS; i++) {
  initialValues[`dancer-${i}-name`] = "";
  initialValues[`dancer-${i}-costumes`] = 1;
}

function Appointment() {
  usePageTitle(messages.title);

  const navigate = useNavigate();
  const { sessionUuid, date } = useParams();

  const blocks = useAppointmentBlocks(sessionUuid);

  const [createAppointment, createAppointmentResponse] =
    useCreateAppointment(sessionUuid);

  useEffect(() => {
    if (createAppointmentResponse.status === 201) {
      navigate(
        `/sign-up/${sessionUuid}/confirm/${createAppointmentResponse.json.appointmentUuid}`
      );
    }
  }, [navigate, sessionUuid, createAppointmentResponse]);

  if (!blocks) {
    return <Loading />;
  }

  let block;
  for (const s of blocks) {
    if (s.date === date) {
      block = s;
      break;
    }
  }
  if (!block) {
    return <Text>not found!</Text>;
  }

  const handleSubmit = (values, formikProps) => {
    const dancers = [];
    for (const [key, value] of Object.entries(values)) {
      const matches = key.match(/^dancer-(\d+)-name$/);
      if (matches && value !== "") {
        dancers.push({
          name: value,
          costumes: values[`dancer-${matches[1]}-costumes`] || 1,
        });
      }
    }
    if (dancers.length > 0) {
      createAppointment(date, dancers);
    } else {
      formikProps.setSubmitting(false);
    }
  };

  return (
    <>
      <Heading size="lg" textAlign="center">
        <AppointmentBlockLabel block={block} />
      </Heading>
      <AddDancersForm
        maxDancers={MAX_DANCERS}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </>
  );
}

export default Appointment;
