import { useMemo } from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function PlaceGroupOrder({ items }) {
  const step = useMemo(() => {
    if (items.length < 1) {
      return 1;
    }

    return 2;
  }, [items]);

  return (
    <>
      <Heading textAlign="center">
        <FormattedMessage id="order.title" />
      </Heading>
      <Text textAlign="center">Order your pictures in two quick steps:</Text>
      <Text textAlign="center" my="8">
        <Button
          as={Link}
          to="add-pictures"
          size="lg"
          variant={step === 1 ? "solid" : "outline"}
          colorScheme={step === 1 ? "blue" : "gray"}
          boxShadow="base"
        >
          1. Select Your Pictures
        </Button>
      </Text>
      <Text textAlign="center" my="8">
        <Button
          as={step < 2 ? undefined : Link}
          to="confirm"
          size="lg"
          variant={step === 2 ? "solid" : "outline"}
          colorScheme={step === 2 ? "blue" : "gray"}
          boxShadow="base"
          disabled={step < 2}
        >
          2. Confirm and Submit
        </Button>
      </Text>
    </>
  );
}

export default PlaceGroupOrder;
