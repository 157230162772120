import { Link } from "react-router-dom";
import { Button, Image, Text } from "@chakra-ui/react";
import { FormattedDate } from "../intl/index.js";
import { DateTime } from "luxon";

function UpcomingSessionButton({ session }) {
  const startDate = DateTime.fromISO(session.startDate);
  const endDate = DateTime.fromISO(session.endDate);

  return (
    <Text textAlign="center">
      <Button
        as={Link}
        to={session.sessionUuid}
        variant="outline"
        height="auto"
        padding="5"
        boxShadow="base"
      >
        <Image
          src={`/logos/${session.organizationId}-small.png`}
          alt="Logo"
          h="24"
        />
        <span>
          {session.name}
          <br />
          <FormattedDate
            value={startDate}
            weekday="long"
            month="long"
            day="numeric"
          />
          {!endDate.hasSame(startDate, "day") && (
            <>
              <br />
              <FormattedDate
                value={endDate}
                weekday="long"
                month="long"
                day="numeric"
              />
            </>
          )}
        </span>
      </Button>
    </Text>
  );
}

export default UpcomingSessionButton;
