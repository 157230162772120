import { defineMessages } from "react-intl";
import * as yup from "lib/yup/index.js";
import { Center, Heading } from "@chakra-ui/react";
import {
  EmailField,
  Form,
  SimpleSelectField,
  SubmitCancelButtonGroup,
  TextField,
} from "components/form/index.js";

const messages = defineMessages({
  nameLabel: { id: "sign-up.field.name.name" },
  namePlaceholder: { id: "sign-up.field.name.placeholder" },
  emailLabel: { id: "sign-up.field.email.name" },
  emailPlaceholder: { id: "sign-up.field.email.placeholder" },
  costumesLabel: {
    id: "sign-up.field.costumes.name",
    defaultMessage: "Costumes",
  },
  costumesPlaceholder: {
    id: "sign-up.field.costumes.placeholder",
    defaultMessage: "Pick One",
  },
});

const costumesOptions = [
  { value: 1, label: messages.nameLabel },
  { value: 2, label: messages.namePlaceholder },
  { value: 3, label: messages.emailLabel },
  { value: 4, label: messages.emailPlaceholder },
];

const validationSchema = yup.object().shape({
  // name: yup.string().required(),
  // email: yup.string().email().required(),
  costumes: yup.number().nullable().required(),
});

function FormTest() {
  const handleSubmit = (values, formik) => {
    alert(JSON.stringify(values));
    formik.setSubmitting(false);
  };

  return (
    <Form
      initialValues={{ name: "", email: "", costumes: 4 }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Heading as="h2" size="md" textAlign="center">
        Just need your contact info…
      </Heading>
      <TextField
        label={messages.nameLabel}
        name="name"
        placeholder={messages.namePlaceholder}
      />
      <EmailField
        label={messages.emailLabel}
        name="email"
        placeholder={messages.emailPlaceholder}
      />
      <SimpleSelectField
        label={messages.costumesLabel}
        name="costumes"
        placeholder={messages.costumesPlaceholder}
        options={costumesOptions}
      />
      <Center>
        <SubmitCancelButtonGroup />
      </Center>
    </Form>
  );
}

export default FormTest;
