import { defineMessages, FormattedMessage } from "react-intl";
import { Heading } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import AppointmentBlockButtonList from "components/appointment/AppointmentBlockButtonList.js";

const messages = defineMessages({
  title: { id: "sign-up.pick-appointment" },
});

function AppointmentSignUp({ session }) {
  usePageTitle(messages.title);

  return (
    <>
      <Heading textAlign="center">
        <FormattedMessage id="sign-up.pick-appointment" />
      </Heading>
      <AppointmentBlockButtonList sessionUuid={session.sessionUuid} />
    </>
  );
}

export default AppointmentSignUp;
