import { Route, Routes } from "react-router-dom";
import Orders from "pages/session/sessionUuid/Orders.js";
import OrderSummaries from "pages/session/sessionUuid/OrderSummaries.js";
import PhotosToPrint from "pages/session/sessionUuid/PhotosToPrint.js";
import Kiosk from "pages/session/sessionUuid/Kiosk.js";
import Manage from "pages/session/sessionUuid/Manage.js";

function SessionRoutes() {
  return (
    <Routes caseSensitive={true}>
      <Route path=":sessionUuid">
        <Route path="orders" element={<Orders />} />
        <Route path="order-summaries" element={<OrderSummaries />} />
        <Route path="photos-to-print" element={<PhotosToPrint />} />
        <Route path="kiosk" element={<Kiosk />} />
        <Route path="manage" element={<Manage />} />
      </Route>
    </Routes>
  );
}

export default SessionRoutes;
