import useJsonApi from "lib/useJsonApi.js";

function useAppointmentPhotoProducts(appointmentUuid, filename) {
  return useJsonApi({
    url: `/api/appointment/${encodeURIComponent(
      appointmentUuid
    )}/photo-products/${encodeURIComponent(filename)}`,
  });
}

export default useAppointmentPhotoProducts;
