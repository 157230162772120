import { Fragment } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

function DancersTable({ dancers, ...props }) {
  return (
    <TableContainer {...props}>
      <Table>
        <Thead>
          <Tr>
            <Th>
              <FormattedMessage id="sign-up.dancer" />
            </Th>
            <Th>
              <FormattedMessage id="sign-up.deposit" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {dancers.map((dancer, index) => (
            <Fragment key={index}>
              <Tr>
                <Td>{dancer.name}</Td>
                <Td>
                  <FormattedNumber
                    value={index === 0 ? 30 : 10}
                    style="currency"
                    currency="USD"
                  />
                </Td>
              </Tr>
              {dancer.costumes > 1 && (
                <Tr>
                  <Td>
                    <FormattedMessage
                      id="dancer-extra-costumes"
                      values={{
                        name: dancer.name,
                        count: dancer.costumes - 1,
                      }}
                    />
                  </Td>
                  <Td>
                    <FormattedNumber
                      value={(dancer.costumes - 1) * 10}
                      style="currency"
                      currency="USD"
                    />
                  </Td>
                </Tr>
              )}
            </Fragment>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default DancersTable;
