import { Box, Flex, Link, Text } from "@chakra-ui/react";
import SpenlenLogo from "components/logos/SpenlenLogo.js";

function SiteFooter({ pt = "10", pb = "5" }) {
  return (
    <Flex
      as="footer"
      pt={pt}
      pb={pb}
      fontSize="xs"
      fontWeight="200"
      color="gray.600"
      direction={{ base: "column", sm: "row" }}
      align={{ base: "center", sm: "baseline" }}
    >
      <Box
        pl={3}
        pr={4}
        order={{ base: 1, sm: 2 }}
        position={{ sm: "relative" }}
        top={{ sm: "0.75em" }}
        flex={{ sm: "0 1 0" }}
      >
        <SpenlenLogo height={2.5} />
      </Box>
      <Text
        mb={0}
        textAlign={{ sm: "right" }}
        order={{ base: 2, sm: 1 }}
        flex={{ sm: "1 1 0" }}
      >
        ©2024 Spenlen Media, Inc.
      </Text>
      <Text mb={0} order={3} flex={{ sm: "1 1 0" }}>
        <Link href="mailto:help@itspictureday.com">help@itspictureday.com</Link>
      </Text>
    </Flex>
  );
}

export default SiteFooter;
