import { BrowserRouter } from "react-router-dom";
import IntlProvider from "./IntlProvider.js";
import ThemeProvider from "./ThemeProvider.js";
import AppRoutes from "./AppRoutes.js";

function App() {
  return (
    <IntlProvider>
      <BrowserRouter>
        <ThemeProvider>
          <AppRoutes />
        </ThemeProvider>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
