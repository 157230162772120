import { forwardRef } from "react";
import { useIntl } from "react-intl";
import { useField } from "formik";
import { Input } from "@chakra-ui/react";
import FormControl from "../FormControl.js";

const InputField = forwardRef(function (
  {
    type,
    id,
    name,
    label,
    placeholder,
    help,
    autoFocus = false,
    disabled = false,
    ...props
  },
  ref
) {
  const intl = useIntl();

  const [{ value, onBlur, onChange }] = useField({
    type,
    name,
  });

  return (
    <FormControl
      id={id}
      name={name}
      label={label}
      help={help}
      isDisabled={disabled}
      {...props}
    >
      <Input
        ref={ref}
        type={type}
        name={name}
        placeholder={
          placeholder && intl.formatMessage(placeholder, placeholder.values)
        }
        value={value ?? ""}
        autoFocus={autoFocus}
        onBlur={onBlur}
        onChange={onChange}
        // aria-errormessage={isInvalid ? `${id}-error` : undefined}
        // aria-labelledby={label && `${id}-label`}
        // aria-describedby={help && `${id}-help`}
      />
    </FormControl>
  );
});
InputField.displayName = "InputField";

export default InputField;
