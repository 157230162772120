import { FormattedDate as IntlFormattedDate } from "react-intl";

// value must be a Luxon DateTime
const FormattedDate = ({
  value,
  timeZone = "local",
  year,
  month,
  weekday,
  day,
  className,
  ...props
}) => {
  return (
    <time dateTime={value.toUTC().toISO()} className={className}>
      <IntlFormattedDate
        value={value.setZone(timeZone).toJSDate()}
        year={year}
        month={month}
        weekday={weekday}
        day={day}
        {...props}
      />
    </time>
  );
};

export default FormattedDate;
