import { defineMessages, FormattedMessage } from "react-intl";
import { Heading, Text } from "@chakra-ui/react";
// import useAppEventSubscription from "lib/appEvent/useAppEventSubscription.js";
// import AppEventType from "lib/appEvent/AppEventType.js";
import usePageTitle from "lib/ui/usePageTitle.js";
import UpcomingSessionsButtonList from "components/signUp/UpcomingSessionsButtonList.js";

const messages = defineMessages({
  title: { id: "sign-up.title" },
});

// const events = [AppEventType.AppointmentConfirmed];

function SignUp() {
  usePageTitle(messages.title);

  // const lastMessage = useAppEventSubscription(events);

  return (
    <>
      {/*<p>{JSON.stringify(lastMessage)}</p>*/}
      <Heading textAlign="center">
        <FormattedMessage id="sign-up.title" />
      </Heading>
      <Text textAlign="center">
        <FormattedMessage id="sign-up.instructions" />
      </Text>
      <UpcomingSessionsButtonList />
    </>
  );
}

export default SignUp;
