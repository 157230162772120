import { useCallback, useState } from "react";
import useJsonApi from "lib/useJsonApi.js";

function useCreateGroupOrder(sessionUuid) {
  const [request, setRequest] = useState({});

  const response = useJsonApi({
    url: request.url,
    method: "POST",
    body: request.body,
  });

  const createGroupOrder = useCallback(
    (name, email) => {
      setRequest({
        url: `/api/session/${encodeURIComponent(
          sessionUuid
        )}/create-group-order`,
        body: { name, email },
      });
    },
    [sessionUuid]
  );

  return [createGroupOrder, response];
}

export default useCreateGroupOrder;
