import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import AppointmentBlockLabel from "./AppointmentBlockLabel.js";

function AvailableAppointmentBlockButton({ block, to, ...params }) {
  return (
    <Button as={RouterLink} to={to} {...params}>
      <AppointmentBlockLabel block={block} />
      <br />
      <FormattedMessage
        id="appointment-block.remaining"
        values={{ remaining: block.remaining }}
      />
    </Button>
  );
}

export default AvailableAppointmentBlockButton;
