import useJsonApi from "lib/useJsonApi.js";

function useOrder(appointmentUuid, orderNumber) {
  return useJsonApi({
    url: `/api/order/${encodeURIComponent(
      appointmentUuid
    )}/${encodeURIComponent(orderNumber)}`,
  });
}

export default useOrder;
