import { defineMessages, FormattedMessage, FormattedNumber } from "react-intl";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { Heading, Link, Text } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointment from "lib/appointments/useAppointment.js";
import Loading from "components/layout/Loading.js";
import DancersTable from "components/signUp/DancersTable.js";

const messages = defineMessages({
  title: { id: "sign-up.title.confirmed" },
});

function Confirmed() {
  usePageTitle(messages.title);

  const params = useParams();

  const response = useAppointment(params.appointmentUuid);
  if (!response.json) {
    return <Loading />;
  }

  if (response.status !== 200) {
    return <Text>Not found</Text>;
  }

  const { appointment, appointmentBlock } = response.json;

  return (
    <>
      <Heading textAlign="center">
        <FormattedMessage id="sign-up.confirmed-title" />
      </Heading>
      <Heading as="h3" size="md" textAlign="center">
        {appointmentBlock.label}
      </Heading>
      <DancersTable dancers={appointment.dancers} mb="8" />
      <Text fontWeight="200">
        {appointment.contactName} - {appointment.contactEmail}
        <br />
        <FormattedMessage
          id="appointment.confirmed-on"
          values={{
            date: DateTime.fromISO(appointment.statusDate),
          }}
        />
        <br />
        {appointment.orderId && (
          <FormattedMessage
            id="appointment.payment-reference"
            values={{
              reference: appointment.orderId,
            }}
          />
        )}
      </Text>
      {appointment.orderId ? (
        <Text>
          <FormattedMessage
            id="sign-up.deposit-paid"
            values={{
              amount: (
                <FormattedNumber
                  value={appointment.deposit}
                  style="currency"
                  currency="USD"
                />
              ),
            }}
          />
        </Text>
      ) : (
        <>
          <Text fontWeight="bold">
            <FormattedMessage
              id="sign-up.deposit-due"
              values={{
                amount: (
                  <FormattedNumber
                    value={appointment.deposit}
                    style="currency"
                    currency="USD"
                  />
                ),
              }}
            />
          </Text>
          <Text>
            <FormattedMessage id="sign-up.deposit-due-instructions" />
          </Text>
        </>
      )}
      <Text>
        <FormattedMessage id="sign-up.confirmed-instructions" />
      </Text>
      <Text mt={8} fontWeight="200" fontSize="sm">
        <FormattedMessage
          id="sign-up.contact-instructions"
          values={{
            link: (
              <Link href="mailto:help@itspictureday.com">
                help@itspictureday.com
              </Link>
            ),
          }}
        />
      </Text>
    </>
  );
}

export default Confirmed;
