import { defineMessages } from "react-intl";
import {
  Box,
  Heading,
  Table,
  Text,
  Td,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useSessionPhotosToPrint from "lib/session/useSessionPhotosToPrint.js";
import Loading from "components/layout/Loading.js";
import { useParams } from "react-router-dom";

const messages = defineMessages({
  title: { id: "session.title.manage" },
});

function ProductBlock({ product }) {
  if (Object.keys(product.photos).length < 1) {
    return null;
  }

  const totalQuantity = Object.values(product.photos).reduce(
    (quantity, photo) => {
      return (quantity += photo.quantity);
    },
    0
  );

  return (
    <Box mt="10">
      <Heading size="sm">
        {product.name} - {product.description}
        <br />
        {product.sku} : {totalQuantity}
      </Heading>
      <Wrap>
        {Object.entries(product.photos).map(([photoFilename, photo]) => (
          <WrapItem
            key={photoFilename}
            width="250px"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
                photo.appointmentUuid
              )}/${encodeURIComponent(photoFilename)}`}
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              height="250px"
              width="250px"
            />
            <Text fontWeight={600}>
              {photo.photoFilename} ({photo.quantity})
            </Text>
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
}

function PhotoBlock({ sessionUuid, photoFilename, products }) {
  if (Object.keys(products).length < 1) {
    return null;
  }

  return (
    <WrapItem key={photoFilename} width="250px" flexDirection="column">
      <Box
        backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
          sessionUuid
        )}/${encodeURIComponent(photoFilename)}`}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="center bottom"
        height="250px"
        width="250px"
      />
      <Heading my={2} size="md">
        {photoFilename}
      </Heading>
      <Table size="sm">
        {Object.entries(products).map(([productSku, product]) => (
          <Tr key={productSku}>
            <Td fontWeight={600}>{product.quantity}</Td>
            <Td>{product.description}</Td>
          </Tr>
        ))}
      </Table>
    </WrapItem>
  );
}
function CategoryBlock({ category }) {
  return (
    <Box px="3" py="5" borderBottom="1px" borderBottomColor="gray.500">
      <Heading size="md">{category.name}</Heading>
      {Object.entries(category.products).map(([sku, product]) => (
        <ProductBlock key={sku} product={product} />
      ))}
    </Box>
  );
}

function PhotosToPrint() {
  usePageTitle(messages.title);

  const { sessionUuid } = useParams();
  const photosResponse = useSessionPhotosToPrint(sessionUuid);

  if (!photosResponse.json) {
    return <Loading />;
  }

  return (
    <>
      <Heading size="lg" textAlign="center">
        Photos To Print
      </Heading>
      <Wrap px="3" py="5" borderBottom="1px" borderBottomColor="gray.500">
        {Object.entries(photosResponse.json.photos).map(
          ([photoFilename, products]) => (
            <PhotoBlock
              key={photoFilename}
              sessionUuid={sessionUuid}
              photoFilename={photoFilename}
              products={products}
            />
          )
        )}
      </Wrap>
      {Object.entries(photosResponse.json.categories).map(([cat, category]) => (
        <CategoryBlock key={cat} category={category} />
      ))}
    </>
  );
}

export default PhotosToPrint;
