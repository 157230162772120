import { useEffect, useState } from "react";

function useJsonApi({ url, method = "GET", body = undefined }) {
  const [response, setResponse] = useState({ json: null, status: 0 });

  useEffect(() => {
    if (!url) {
      return; // for deferred calls
    }
    const controller = new AbortController();

    let cancelled = false;
    (async () => {
      const headers = {
        Accept: "application/json",
      };
      if (body) {
        headers["Content-Type"] = "application/json";
      }

      try {
        const res = await fetch(url, {
          method,
          body: body && JSON.stringify(body),
          headers,
          mode: "same-origin",
          redirect: "error",
          signal: controller.signal,
        });
        const text = await res.text();
        try {
          const json = text ? JSON.parse(text) : {};
          if (!cancelled) {
            setResponse({ json, status: res.status });
          }
        } catch (error) {
          throw new Error(`Unable to parse JSON respone: ${error}`);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          if (!cancelled) {
            setResponse({ json: null, status: -1 });
          }
        } else {
          throw error;
        }
      }
    })();

    return () => {
      cancelled = true;
      controller.abort();
    };
  }, [url, method, body]);

  return response;
}

export default useJsonApi;
