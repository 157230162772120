import { Outlet, useParams } from "react-router-dom";
import { Center, Image } from "@chakra-ui/react";
import useSession from "../../../lib/session/useSession.js";
import Loading from "../Loading.js";

function SessionLayout() {
  const { sessionUuid } = useParams();

  const sessionResponse = useSession(sessionUuid);

  if (!sessionResponse.json) {
    return <Loading />;
  }

  return (
    <>
      <Center mb="5">
        <Image
          src={`/logos/${sessionResponse.json.session.organizationId}-small.png`}
          alt="Logo"
          h="24"
        />
      </Center>
      <Outlet />
    </>
  );
}

export default SessionLayout;
