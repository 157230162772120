import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      html: {
        height: "100vh",
      },
      body: {
        minHeight: "100vh",
      },
      p: {
        mb: "1em",
      },
    },
  },
  colors: {
    primary: "#1c75bc",
    secondary: "#9e1f63",
  },
  fonts: {
    body: "freight-neo-pro, system-ui, sans-serif",
    heading: "freight-neo-pro, serif",
  },
  components: {
    Heading: {
      baseStyle: {
        mb: "0.7em",
        fontWeight: "900", // Black
        color: "secondary",
      },
      variants: {
        site: {
          mb: "0.2em",
          fontFamily: "PhosphateRRInline, sans-serif",
          fontWeight: "normal",
          lineHeight: "1.2",
          color: "primary",
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: "600",
      },
    },
    Form: {
      // FormControl
      baseStyle: {
        container: {
          marginBottom: 4,
        },
        requiredIndicator: {
          fontSize: "0.8em",
          fontWeight: "200",
          color: "gray.500",
        },
      },
    },
  },
});

function ThemeProvider({ children }) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}

export default ThemeProvider;

/*
FreightNeo Pro Bold

font-family: freight-neo-pro, sans-serif;
font-weight: 700;
font-style: normal;

FreightNeo Pro Bold Italic

font-family: freight-neo-pro, sans-serif;
font-weight: 700;
font-style: italic;

FreightNeo Pro Light

font-family: freight-neo-pro, sans-serif;
font-weight: 300;
font-style: normal;

FreightNeo Pro Light Italic

font-family: freight-neo-pro, sans-serif;
font-weight: 300;
font-style: italic;

FreightNeo Pro Medium

font-family: freight-neo-pro, sans-serif;
font-weight: 500;
font-style: normal;

FreightNeo Pro Medium Italic

font-family: freight-neo-pro, sans-serif;
font-weight: 500;
font-style: italic;
 */
