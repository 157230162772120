import { useCallback, useState } from "react";
import useJsonApi from "lib/useJsonApi.js";

function useConfirmOrder(appointment, order) {
  const [request, setRequest] = useState({});

  const response = useJsonApi({
    url: request.url,
    method: "POST",
    body: request.body,
  });

  const confirmOrder = useCallback(
    (name, email, method) => {
      setRequest({
        url: `/api/order/${encodeURIComponent(
          appointment.appointmentUuid
        )}/${encodeURIComponent(order.orderNumber)}/confirm`,
        body: { name, email, method },
      });
    },
    [appointment, order]
  );

  return [confirmOrder, response];
}

export default useConfirmOrder;
