import { useParams } from "react-router-dom";
import useSession from "lib/session/useSession.js";
import Loading from "components/layout/Loading.js";
import GroupSignUp from "components/signUp/GroupSignUp.js";
import AppointmentSignUp from "components/signUp/AppointmentSignUp.js";

function Session() {
  const { sessionUuid } = useParams();
  const sessionResponse = useSession(sessionUuid);

  if (!sessionResponse.json) {
    return <Loading />;
  }

  switch (sessionResponse.json.session?.type) {
    case "group":
      return <GroupSignUp session={sessionResponse.json.session} />;
    case "appoint":
      return <AppointmentSignUp session={sessionResponse.json.session} />;
    default:
      return <p>Not found</p>;
  }
}

export default Session;
