import { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage, useField, useFormikContext } from "formik";
import {
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { useValidationSchemaContext } from "./Form.js";
import FormLabel from "./FormLabel.js";

const FormControl = forwardRef(function (
  { name, label, help, isDisabled, children, ...props },
  ref
) {
  const { isSubmitting } = useFormikContext();
  const validationSchema = useValidationSchemaContext();

  const [, { touched, error }] = useField(name);

  const isRequired =
    validationSchema?.fields[name]?.exclusiveTests?.required || false;

  return (
    <ChakraFormControl
      ref={ref}
      isInvalid={touched && error}
      isRequired={isRequired}
      isDisabled={isSubmitting || isDisabled}
      {...props}
    >
      <FormLabel label={label} />
      {children}
      <ErrorMessage name={name}>
        {(errorMessage) => (
          <FormErrorMessage>
            <FormattedMessage {...errorMessage} />
          </FormErrorMessage>
        )}
      </ErrorMessage>
      {help && (
        <FormHelperText>
          <FormattedMessage {...help} />
        </FormHelperText>
      )}
    </ChakraFormControl>
  );
});
FormControl.displayName = "FormControl";

export default FormControl;
