import { ButtonGroup } from "@chakra-ui/react";
import SubmitButton from "./SubmitButton.js";
import CancelButton from "./CancelButton.js";

function SubmitCancelButtonGroup({ onCancel, submitMessage, ...props }) {
  return (
    <ButtonGroup spacing={3} {...props}>
      <SubmitButton submitMessage={submitMessage} />
      <CancelButton onClick={onCancel} />
    </ButtonGroup>
  );
}

export default SubmitCancelButtonGroup;
