import { forwardRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useField } from "formik";
import { Select } from "@chakra-ui/react";
import FormControl from "../FormControl.js";

const SimpleSelectField = forwardRef(function (
  { id, name, label, placeholder, options, help, disabled = false, ...props },
  ref
) {
  const intl = useIntl();

  const [{ value, onBlur }, , { setValue }] = useField({
    type: "select",
    name,
  });

  const handleChange = (event) => {
    if (event.target.selectedIndex === -1) {
      setValue(null);
    } else if (placeholder) {
      if (event.target.selectedIndex === 0) {
        setValue(null); // placeholder option
      } else {
        setValue(options[event.target.selectedIndex - 1].value);
      }
    } else {
      setValue(options[event.target.selectedIndex].value);
    }
  };

  return (
    <FormControl
      id={id}
      name={name}
      label={label}
      help={help}
      isDisabled={disabled}
      {...props}
    >
      <Select
        ref={ref}
        name={name}
        placeholder={
          placeholder && intl.formatMessage(placeholder, placeholder.values)
        }
        value={value ?? ""}
        onBlur={onBlur}
        onChange={handleChange}
        // aria-errormessage={isInvalid ? `${id}-error` : undefined}
        // aria-labelledby={label && `${id}-label`}
        // aria-describedby={help && `${id}-help`}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            <FormattedMessage {...option.label} />
          </option>
        ))}
      </Select>
    </FormControl>
  );
});
SimpleSelectField.displayName = "SimpleSelectField";

export default SimpleSelectField;
