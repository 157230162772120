import { defineMessages, FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { Text } from "@chakra-ui/react";
import * as yup from "lib/yup/index.js";
import {
  Button,
  EmailField,
  Form,
  SubmitButton,
  TextField,
} from "components/form/index.js";

const messages = defineMessages({
  nameLabel: { id: "sign-up.field.name.name" },
  namePlaceholder: { id: "sign-up.field.name.placeholder" },
  emailLabel: { id: "sign-up.field.email.name" },
  emailPlaceholder: { id: "sign-up.field.email.placeholder" },
  submitMessage: { id: "sign-up.button.pay-deposit-online-now" },
});

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
});

function ConfirmAppointmentForm({ initialValues, onSubmit }) {
  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <TextField
        label={messages.nameLabel}
        name="name"
        placeholder={messages.namePlaceholder}
        mb="2"
      />
      <EmailField
        label={messages.emailLabel}
        name="email"
        placeholder={messages.emailPlaceholder}
        mb="8"
      />
      <ConfirmFormButtons />
    </Form>
  );
}

function ConfirmFormButtons() {
  const { setFieldValue, submitForm } = useFormikContext();

  const handlePayNowClick = async () => {
    setFieldValue("method", "paynow");
    await submitForm();
  };

  const handleBringWithMeClick = async () => {
    setFieldValue("method", "bring");
    await submitForm();
  };

  return (
    <>
      <Text textAlign="center">
        <SubmitButton
          type="button"
          submitMessage={messages.submitMessage}
          onClick={handlePayNowClick}
        />
      </Text>
      <Text textAlign="center">
        <Button onClick={handleBringWithMeClick}>
          <FormattedMessage id="sign-up.button.bring-with-me" />
        </Button>
      </Text>
    </>
  );
}

export default ConfirmAppointmentForm;
