import useJsonApi from "lib/useJsonApi.js";

function useAppointmentBlocks(sessionUuid) {
  const response = useJsonApi({
    url: `/api/appointment-block/${encodeURIComponent(sessionUuid)}`,
  });
  return response.json;
}

export default useAppointmentBlocks;
