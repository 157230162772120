import { defineMessages } from "react-intl";
import { wrapNonLocalizedMessage } from "lib/intl/index.js";
import { SimpleSelectField, TextField } from "components/form/index.js";

const messages = defineMessages({
  nameLabel: { id: "sign-up.field.dancer-name.name" },
  namePlaceholder: { id: "sign-up.field.dancer-name.placeholder" },
  costumesLabel: {
    id: "sign-up.field.costumes.name",
    defaultMessage: "Costumes",
  },
});

const costumesOptions = [];
for (let i = 1; i <= 8; i++) {
  costumesOptions.push({ value: i, label: wrapNonLocalizedMessage(i) });
}

function DancerFormFields({ dancerNumber }) {
  return (
    <>
      <TextField
        label={messages.nameLabel}
        name={`dancer-${dancerNumber}-name`}
        placeholder={messages.namePlaceholder}
        autoFocus={true}
        mb="2"
      />
      <SimpleSelectField
        label={messages.costumesLabel}
        name={`dancer-${dancerNumber}-costumes`}
        options={costumesOptions}
      />
    </>
  );
}

export default DancerFormFields;
