import { forwardRef } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useFormikContext } from "formik";
import { Button } from "@chakra-ui/react";

const messages = defineMessages({
  submit: { id: "button.submit" },
  submitting: { id: "button.submitting" },
});

const SubmitButton = forwardRef(function (
  {
    submitMessage = messages.submit,
    submittingMessage = messages.submitting,
    colorScheme = "blue",
    type = "submit",
    disabled = false,
    ...props
  },
  ref
) {
  const intl = useIntl();

  const { isSubmitting, isValid, submitCount } = useFormikContext();

  return (
    <Button
      ref={ref}
      type={type}
      isLoading={isSubmitting}
      loadingText={intl.formatMessage(
        submittingMessage,
        submittingMessage.values
      )}
      colorScheme={isValid || submitCount < 1 ? colorScheme : "yellow"}
      disabled={isSubmitting || disabled}
      {...props}
    >
      <FormattedMessage {...submitMessage} />
    </Button>
  );
});
SubmitButton.displayName = "SubmitButton";

export default SubmitButton;
