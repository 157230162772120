import { Center, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";

function Loading({ delayMs = 100 }) {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHidden(false);
    }, delayMs);
    return () => {
      clearTimeout(timer);
    };
  }, [delayMs]);

  return (
    <Center>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="primary"
        size="xl"
        label="Loading"
        opacity={hidden ? 0 : 1}
        transition="opacity 1.5s ease-in"
      />
    </Center>
  );
}

export default Loading;
