import { useEffect } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Heading, Text } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useCreateGroupOrder from "lib/session/useCreateGroupOrder.js";
import CreateGroupOrderForm from "./CreateGroupOrderForm.js";

const messages = defineMessages({
  title: { id: "sign-up.begin-your-order" },
});

function GroupSignUp({ session }) {
  usePageTitle(messages.title);

  const navigate = useNavigate();
  const [createGroupOrder, response] = useCreateGroupOrder(session.sessionUuid);

  useEffect(() => {
    switch (response.status) {
      case 0:
        // no request yet
        break;
      case 200:
      case 201:
        navigate(`/order/${session.sessionUuid}/${response.json.orderNumber}`);
        break;
      case 400:
        throw new Error("Orders are closed");
      default:
        throw new Error(
          `Unexpected response (${response.status}) when attempting to create group order`
        );
    }
  }, [navigate, session.sessionUuid, response]);

  const handleSubmit = ({ name, email }) => {
    createGroupOrder(name, email);
  };

  const handleCancel = () => {
    navigate("/sign-up");
  };

  return (
    <>
      <Heading textAlign="center">
        <FormattedMessage id="sign-up.begin-your-order" />
      </Heading>
      <Text textAlign="center">
        <FormattedMessage id="sign-up.group-order-form-instructions" />
      </Text>
      <CreateGroupOrderForm
        initialValues={{ name: "", email: "" }}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
}

export default GroupSignUp;
