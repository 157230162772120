function SpenlenLogo({ height = 1, style = {} }) {
  style = {
    width: `${height * 3.375}em`,
    height: `${height}em`,
    ...style,
  };
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 583.2 172.8"
      enableBackground="new 0 0 583.2 172.8"
      style={style}
    >
      <path
        d="M39.84,162.49c-0.05-1.06-0.52-2.04-1.31-2.75c-0.79-0.71-1.81-1.08-2.87-1.02c-1.07,0.05-2.04,0.52-2.76,1.31
  c-1.47,1.63-1.34,4.16,0.29,5.63c0.8,0.72,1.82,1.08,2.88,1.02c1.06-0.06,2.04-0.52,2.75-1.31c0.45-0.5,0.76-1.09,0.92-1.73
  C39.83,163.27,39.87,162.88,39.84,162.49z"
      />
      <path
        d="M46.04,151.04c-0.79-0.71-1.82-1.07-2.88-1.02c-1.06,0.05-2.04,0.52-2.75,1.31c-0.71,0.79-1.08,1.81-1.02,2.87
  c0.05,1.06,0.52,2.04,1.31,2.75c1.63,1.47,4.16,1.34,5.63-0.29v0C47.79,155.04,47.66,152.52,46.04,151.04z"
      />
      <path
        d="M54.84,145.11c-0.06-1.07-0.52-2.04-1.31-2.76c-0.79-0.71-1.81-1.07-2.87-1.02c-1.07,0.05-2.05,0.52-2.76,1.31
  c-1.47,1.63-1.34,4.16,0.29,5.63c0.79,0.72,1.81,1.08,2.88,1.02c1.06-0.05,2.04-0.52,2.75-1.31c0.45-0.5,0.76-1.1,0.92-1.73
  C54.83,145.88,54.86,145.5,54.84,145.11z"
      />
      <path
        d="M61.04,133.66c-0.79-0.72-1.81-1.08-2.88-1.02c-1.06,0.05-2.04,0.52-2.75,1.31c-0.71,0.79-1.08,1.81-1.02,2.88
  c0.05,1.06,0.52,2.04,1.31,2.76c1.63,1.47,4.16,1.34,5.63-0.29l0,0C62.8,137.66,62.67,135.13,61.04,133.66z"
      />
      <path
        d="M10.18,77.57c0.94-0.5,1.63-1.33,1.95-2.35c0.32-1.02,0.22-2.1-0.28-3.04c-0.5-0.94-1.33-1.63-2.35-1.95
				c-2.1-0.65-4.34,0.53-4.99,2.63c-0.31,1.02-0.21,2.1,0.29,3.04c0.5,0.94,1.33,1.63,2.35,1.94c0.64,0.2,1.31,0.23,1.96,0.1
				C9.48,77.88,9.84,77.75,10.18,77.57z"
      />
      <path
        d="M23.17,78.36c0.31-1.02,0.21-2.1-0.29-3.04c-0.5-0.94-1.33-1.63-2.35-1.95c-1.01-0.31-2.09-0.21-3.04,0.28
				c-0.94,0.5-1.64,1.33-1.95,2.35c-0.65,2.1,0.53,4.34,2.63,4.98h0C20.29,81.64,22.53,80.46,23.17,78.36z"
      />
      <path
        d="M32.27,83.86c0.94-0.5,1.63-1.33,1.95-2.35c0.32-1.02,0.21-2.1-0.28-3.04c-0.5-0.94-1.33-1.63-2.35-1.95
				c-2.1-0.64-4.33,0.54-4.98,2.64c-0.31,1.02-0.21,2.1,0.28,3.04c0.5,0.94,1.33,1.63,2.35,1.94c0.65,0.2,1.32,0.23,1.96,0.1
				C31.56,84.17,31.92,84.04,32.27,83.86z"
      />
      <path
        d="M45.26,84.65c0.32-1.02,0.21-2.1-0.28-3.04c-0.5-0.94-1.33-1.63-2.35-1.95c-1.02-0.31-2.09-0.21-3.04,0.28
				c-0.95,0.5-1.64,1.33-1.95,2.35c-0.65,2.1,0.54,4.33,2.64,4.98l0,0C42.38,87.93,44.61,86.75,45.26,84.65z"
      />
      <path
        d="M106.13,49.2c-0.46-0.96-1.27-1.68-2.28-2.03c-1-0.35-2.08-0.29-3.05,0.17c-0.96,0.46-1.68,1.27-2.04,2.27
				c-0.73,2.07,0.37,4.35,2.44,5.08c1.01,0.35,2.09,0.29,3.05-0.17c0.96-0.46,1.68-1.27,2.03-2.27c0.22-0.63,0.28-1.3,0.17-1.95
				C106.42,49.92,106.3,49.55,106.13,49.2z"
      />
      <path
        d="M107.41,36.24c-1.01-0.35-2.09-0.29-3.05,0.17c-0.96,0.46-1.68,1.27-2.03,2.27c-0.35,1-0.29,2.08,0.17,3.04
				c0.46,0.96,1.27,1.68,2.27,2.04c2.08,0.72,4.36-0.37,5.08-2.45l0,0C110.58,39.25,109.49,36.97,107.41,36.24z"
      />
      <path
        d="M113.24,27.36c-0.46-0.96-1.27-1.68-2.27-2.04c-1.01-0.35-2.09-0.29-3.04,0.17c-0.96,0.46-1.68,1.27-2.04,2.28
				c-0.72,2.07,0.37,4.35,2.45,5.08c1.01,0.35,2.09,0.29,3.05-0.17c0.96-0.46,1.68-1.27,2.03-2.27c0.22-0.64,0.28-1.31,0.17-1.95
				C113.53,28.08,113.41,27.71,113.24,27.36z"
      />
      <path
        d="M114.52,14.41c-1.01-0.36-2.09-0.29-3.05,0.17c-0.96,0.46-1.68,1.27-2.03,2.27c-0.35,1-0.29,2.08,0.17,3.04
				c0.46,0.96,1.27,1.68,2.27,2.03c2.07,0.72,4.35-0.37,5.08-2.44v0C117.69,17.42,116.6,15.14,114.52,14.41z"
      />
      <path
        d="M120.35,5.53c-0.46-0.96-1.27-1.69-2.27-2.04c-1-0.35-2.09-0.29-3.05,0.17c-0.96,0.46-1.68,1.27-2.04,2.27
				c-0.73,2.07,0.37,4.35,2.44,5.08c1.01,0.35,2.09,0.29,3.05-0.17c0.96-0.46,1.68-1.27,2.03-2.27c0.22-0.64,0.28-1.31,0.18-1.95
				C120.64,6.25,120.52,5.88,120.35,5.53z"
      />
      <path
        d="M67.01,50.86c-0.97-0.45-2.05-0.49-3.05-0.12c-1,0.37-1.79,1.11-2.24,2.07c-0.44,0.97-0.49,2.05-0.11,3.05
				c0.76,2.06,3.06,3.11,5.12,2.35c1-0.38,1.8-1.11,2.24-2.08c0.45-0.97,0.48-2.05,0.11-3.05c-0.24-0.63-0.62-1.18-1.11-1.61
				C67.68,51.23,67.36,51.02,67.01,50.86z"
      />
      <path
        d="M59.73,40.07c-1,0.37-1.79,1.11-2.24,2.08c-0.45,0.97-0.48,2.05-0.11,3.05c0.37,1,1.11,1.79,2.07,2.24
				c0.97,0.45,2.05,0.49,3.05,0.11c2.06-0.77,3.11-3.07,2.35-5.13v0C64.08,40.37,61.79,39.31,59.73,40.07z"
      />
      <path
        d="M58.55,29.51c-0.97-0.44-2.05-0.48-3.05-0.11c-1,0.37-1.79,1.1-2.24,2.07c-0.45,0.97-0.49,2.05-0.12,3.05
				c0.77,2.06,3.06,3.11,5.13,2.35c1-0.37,1.79-1.11,2.24-2.08c0.44-0.97,0.48-2.05,0.11-3.05c-0.23-0.63-0.62-1.18-1.11-1.62
				C59.22,29.89,58.9,29.68,58.55,29.51z"
      />
      <path
        d="M51.27,18.72c-1,0.37-1.8,1.11-2.24,2.08c-0.44,0.97-0.48,2.05-0.11,3.05c0.37,1,1.1,1.79,2.07,2.24
				c0.97,0.44,2.05,0.48,3.05,0.11c2.06-0.77,3.12-3.07,2.35-5.13v0C55.62,19.02,53.33,17.96,51.27,18.72z"
      />
      <path
        d="M50.09,8.17c-0.97-0.44-2.05-0.48-3.05-0.12c-0.99,0.37-1.79,1.11-2.24,2.07c-0.44,0.97-0.48,2.05-0.11,3.06
				c0.76,2.06,3.06,3.11,5.12,2.35c1.01-0.37,1.8-1.11,2.24-2.08c0.44-0.97,0.48-2.05,0.11-3.05c-0.23-0.63-0.62-1.18-1.11-1.62
				C50.76,8.54,50.44,8.33,50.09,8.17z"
      />
      <path
        d="M415.62,83.02l-10.93,37.65h-13.56L380.2,83.1l-0.31,38.36h-11.81l1.75-49.85h17.95l10.37,37.41l10.37-37.41h17.7
			l1.68,49.85h-12.05L415.62,83.02z"
      />
      <path
        d="M446.45,105.78c0.21,2.52,1.14,4.46,2.79,5.83c1.65,1.37,3.99,2.05,7.03,2.05c2.02,0,4.08-0.33,6.18-0.99
			c2.1-0.66,4.08-1.52,5.94-2.59l1.2,9.51c-1.86,0.96-4.06,1.71-6.58,2.24c-2.52,0.53-5.14,0.8-7.85,0.8
			c-3.13,0-5.97-0.43-8.52-1.28c-2.55-0.85-4.73-2.1-6.53-3.76c-1.8-1.65-3.2-3.69-4.18-6.12c-0.98-2.42-1.47-5.18-1.47-8.27
			c0-3.04,0.49-5.79,1.47-8.27c0.98-2.47,2.36-4.58,4.14-6.31c1.78-1.73,3.9-3.08,6.37-4.04c2.47-0.96,5.17-1.44,8.09-1.44
			c5.26,0,9.28,1.44,12.07,4.3c2.79,2.87,4.18,6.62,4.18,11.24c0,1.01-0.07,2.15-0.2,3.43c-0.13,1.28-0.33,2.31-0.6,3.11l-23.54,0.4
			V105.78z M459.38,99.38v-0.71c0-2.21-0.46-3.93-1.39-5.16c-0.93-1.23-2.35-1.85-4.26-1.85c-2.12,0-3.79,0.78-5.01,2.33
			c-1.21,1.55-1.93,3.38-2.14,5.48L459.38,99.38z"
      />
      <path
        d="M502,68.71l12.34-1.52v54.27H502l0.05-3.77c-1.17,1.22-2.61,2.35-4.31,3.38c-1.7,1.04-3.72,1.55-6.07,1.55
			c-2.29,0-4.4-0.46-6.34-1.36c-1.94-0.91-3.62-2.2-5.03-3.87c-1.41-1.68-2.51-3.7-3.31-6.07c-0.8-2.37-1.2-5.02-1.2-7.95
			c0-3.08,0.5-5.87,1.51-8.34c1.01-2.47,2.36-4.59,4.06-6.35c1.7-1.76,3.66-3.1,5.9-4.03c2.23-0.93,4.59-1.4,7.09-1.4
			c2.65,0,5.21,0.42,7.65,1.27V68.71z M499.54,92.79c-0.95-0.29-2.01-0.44-3.17-0.44c-2.59,0-4.6,0.96-6.06,2.88
			c-1.46,1.92-2.18,4.43-2.18,7.53c0,3.2,0.71,5.68,2.14,7.44c1.43,1.76,3.28,2.65,5.55,2.65c1.37,0,2.55-0.23,3.52-0.68
			c0.97-0.45,1.86-1.08,2.65-1.88V93.95C501.31,93.47,500.49,93.08,499.54,92.79z"
      />
      <path
        d="M536.64,72.53c0,2.13-0.64,3.75-1.92,4.87c-1.28,1.12-3.07,1.67-5.36,1.67c-2.24,0-4.01-0.56-5.32-1.67
			c-1.3-1.12-1.96-2.74-1.96-4.87c0-2.18,0.65-3.83,1.96-4.94c1.31-1.12,3.08-1.68,5.32-1.68c2.29,0,4.08,0.56,5.36,1.68
			C536,68.7,536.64,70.35,536.64,72.53z M523.25,121.46V85.01l12.34-1.52v37.97H523.25z"
      />
      <path
        d="M564.34,116.98c-1.33,1.54-2.81,2.87-4.44,3.98c-1.62,1.11-3.71,1.67-6.27,1.67c-1.6,0-3.08-0.27-4.46-0.8
			c-1.38-0.53-2.57-1.26-3.58-2.19c-1.01-0.93-1.8-2.02-2.39-3.27c-0.59-1.25-0.88-2.59-0.88-4.03c0-1.75,0.35-3.4,1.08-4.94
			c0.71-1.54,1.92-2.9,3.62-4.07c1.7-1.17,3.96-2.13,6.77-2.87c2.82-0.74,6.29-1.17,10.43-1.28v-0.72c0-1.97-0.51-3.4-1.55-4.3
			c-1.03-0.9-2.69-1.36-4.97-1.36c-1.91,0-3.94,0.36-6.09,1.08c-2.15,0.72-4.32,1.61-6.49,2.67l-2.55-9.02
			c1.97-1.07,4.43-2.06,7.38-3c2.95-0.93,6.18-1.4,9.69-1.4c3.19,0,5.87,0.36,8.05,1.08c2.18,0.72,3.94,1.77,5.26,3.15
			c1.33,1.38,2.27,3.07,2.83,5.06c0.56,2,0.81,4.3,0.76,6.9l-0.08,11.01c0,1.28,0.19,2.08,0.56,2.39c0.37,0.32,1.2,0.53,2.47,0.64
			l-1.2,8.77c-4.04,0.52-7.12,0.29-9.25-0.68c-2.13-0.98-3.61-2.48-4.46-4.5H564.34z M564.23,104.9c-3.83,0.42-6.51,1.05-8.05,1.88
			c-1.54,0.82-2.31,2.06-2.31,3.71c0,1.22,0.42,2.19,1.27,2.91c0.85,0.72,1.97,1.08,3.35,1.08c1.23,0,2.33-0.22,3.31-0.68
			c0.99-0.45,1.8-1.05,2.43-1.79V104.9z"
      />
      <path
        d="M87.76,112.15c2.23,0,4.05-0.4,5.46-1.2c1.41-0.8,2.11-1.97,2.11-3.51c0-0.85-0.23-1.57-0.68-2.15
				c-0.45-0.58-1.1-1.1-1.95-1.55c-0.85-0.45-1.91-0.88-3.19-1.27c-1.27-0.4-2.73-0.84-4.38-1.32c-1.81-0.48-3.65-1.09-5.54-1.83
				c-1.88-0.74-3.61-1.69-5.18-2.83c-1.57-1.14-2.84-2.58-3.82-4.3c-0.98-1.73-1.47-3.84-1.47-6.34c0-4.62,1.65-8.34,4.94-11.16
				c3.29-2.82,8.02-4.22,14.17-4.22c3.93,0,7.42,0.4,10.47,1.2c3.05,0.8,5.77,1.86,8.17,3.19l-2.47,10.45
				c-2.49-1.32-5.17-2.37-8.03-3.17c-2.86-0.79-5.54-1.19-8.03-1.19c-4.35,0-6.52,1.41-6.52,4.23c0,0.8,0.26,1.5,0.8,2.11
				c0.53,0.61,1.27,1.14,2.23,1.6c0.96,0.45,2.07,0.88,3.34,1.27c1.27,0.4,2.65,0.81,4.14,1.24c1.91,0.53,3.79,1.17,5.65,1.92
				c1.85,0.75,3.52,1.69,5.01,2.83c1.49,1.14,2.68,2.55,3.58,4.22c0.9,1.67,1.35,3.71,1.35,6.1c0,2.39-0.45,4.57-1.35,6.54
				c-0.9,1.97-2.22,3.67-3.94,5.1c-1.72,1.44-3.85,2.55-6.37,3.35c-2.52,0.8-5.43,1.2-8.72,1.2c-3.72,0-7.26-0.43-10.63-1.28
				c-3.37-0.85-6.23-1.94-8.56-3.27l2.39-10.53c2.49,1.32,5.19,2.42,8.08,3.29C81.7,111.71,84.69,112.15,87.76,112.15z"
      />
      <path
        d="M132.01,84.76c1.66-1.08,3.69-1.61,6.07-1.61c2.27,0,4.37,0.45,6.3,1.36c1.93,0.91,3.6,2.2,5,3.88
				c1.4,1.68,2.5,3.7,3.29,6.07c0.79,2.37,1.19,5.05,1.19,8.03c0,3.09-0.49,5.88-1.47,8.35c-0.98,2.48-2.31,4.6-4,6.35
				c-1.69,1.76-3.66,3.1-5.91,4.04c-2.25,0.93-4.61,1.4-7.1,1.4c-1.22,0-2.5-0.11-3.85-0.32c-1.35-0.21-2.6-0.51-3.77-0.88V138
				h-12.35V85.01l12.35-1.51v4.71C128.93,86.98,130.35,85.83,132.01,84.76z M127.77,111.91c1.52,1.02,3.37,1.52,5.58,1.52
				c2.61,0,4.63-0.95,6.04-2.84c1.41-1.9,2.12-4.39,2.12-7.49c0-3.2-0.64-5.7-1.93-7.49c-1.28-1.79-3.18-2.68-5.69-2.68
				c-1.36,0-2.51,0.24-3.46,0.72c-0.94,0.48-1.83,1.09-2.67,1.84V111.91z"
      />
      <path
        d="M170.92,105.78c0.21,2.52,1.14,4.46,2.79,5.83c1.65,1.37,3.99,2.05,7.02,2.05c2.02,0,4.08-0.33,6.19-0.99
				c2.1-0.66,4.08-1.52,5.95-2.59l1.19,9.51c-1.86,0.96-4.05,1.71-6.58,2.24c-2.52,0.53-5.14,0.8-7.85,0.8
				c-3.13,0-5.97-0.43-8.52-1.28c-2.55-0.85-4.73-2.1-6.54-3.76c-1.81-1.65-3.2-3.69-4.18-6.12c-0.98-2.42-1.48-5.18-1.48-8.27
				c0-3.04,0.49-5.79,1.48-8.27c0.98-2.47,2.36-4.58,4.14-6.31c1.78-1.73,3.91-3.08,6.38-4.04c2.47-0.96,5.16-1.44,8.09-1.44
				c5.26,0,9.28,1.44,12.07,4.3c2.79,2.87,4.18,6.62,4.18,11.24c0,1.01-0.07,2.15-0.2,3.43c-0.13,1.28-0.33,2.31-0.6,3.11
				l-23.54,0.4V105.78z M183.85,99.38v-0.71c0-2.21-0.46-3.93-1.39-5.16c-0.93-1.23-2.35-1.85-4.26-1.85
				c-2.12,0-3.79,0.78-5.01,2.33c-1.22,1.55-1.93,3.38-2.15,5.48L183.85,99.38z"
      />
      <path
        d="M226.34,121.46V99.53c0-2.23-0.46-3.77-1.37-4.62c-0.92-0.85-2.19-1.28-3.8-1.28c-1.36,0-2.6,0.28-3.73,0.84
				c-1.12,0.56-2.08,1.21-2.86,1.96v25.04h-12.34V85.01l12.34-1.51l-0.09,6.05c1.63-1.87,3.5-3.4,5.6-4.6
				c2.11-1.2,4.34-1.8,6.71-1.8c3.74,0,6.66,1.13,8.75,3.39c2.09,2.26,3.13,5.39,3.13,9.38v25.54H226.34z"
      />
      <path d="M247.2,121.46V68.71l12.34-1.51v54.27H247.2z" />
      <path
        d="M278.4,105.78c0.21,2.52,1.14,4.46,2.79,5.83c1.65,1.37,3.99,2.05,7.03,2.05c2.02,0,4.08-0.33,6.18-0.99
				c2.1-0.66,4.08-1.52,5.95-2.59l1.2,9.51c-1.86,0.96-4.05,1.71-6.58,2.24c-2.52,0.53-5.14,0.8-7.85,0.8
				c-3.13,0-5.97-0.43-8.53-1.28c-2.55-0.85-4.73-2.1-6.53-3.76c-1.81-1.65-3.2-3.69-4.18-6.12c-0.98-2.42-1.47-5.18-1.47-8.27
				c0-3.04,0.49-5.79,1.47-8.27c0.98-2.47,2.36-4.58,4.14-6.31c1.78-1.73,3.9-3.08,6.38-4.04c2.47-0.96,5.17-1.44,8.09-1.44
				c5.26,0,9.28,1.44,12.07,4.3c2.79,2.87,4.18,6.62,4.18,11.24c0,1.01-0.07,2.15-0.2,3.43c-0.13,1.28-0.33,2.31-0.6,3.11
				l-23.54,0.4V105.78z M291.32,99.38v-0.71c0-2.21-0.46-3.93-1.39-5.16c-0.93-1.23-2.35-1.85-4.26-1.85
				c-2.12,0-3.79,0.78-5.01,2.33c-1.22,1.55-1.94,3.38-2.15,5.48L291.32,99.38z"
      />
      <path
        d="M333.81,121.46V99.53c0-2.23-0.45-3.77-1.37-4.62c-0.91-0.85-2.18-1.28-3.8-1.28c-1.36,0-2.6,0.28-3.73,0.84
				c-1.12,0.56-2.08,1.21-2.86,1.96v25.04h-12.35V85.01l12.35-1.51l-0.09,6.05c1.63-1.87,3.5-3.4,5.6-4.6c2.1-1.2,4.34-1.8,6.71-1.8
				c3.75,0,6.66,1.13,8.75,3.39c2.08,2.26,3.13,5.39,3.13,9.38v25.54H333.81z"
      />
      <path
        d="M101.62,153.56c-0.93-0.52-2.01-0.64-3.03-0.35c-1.02,0.29-1.87,0.96-2.39,1.89c-0.52,0.94-0.64,2.01-0.35,3.03
					c0.6,2.12,2.81,3.34,4.93,2.74c1.03-0.29,1.87-0.97,2.39-1.9c0.52-0.93,0.64-2.01,0.34-3.03c-0.18-0.65-0.52-1.23-0.98-1.7
					C102.26,153.99,101.95,153.75,101.62,153.56z"
      />
      <path
        d="M95.19,142.24c-1.02,0.29-1.87,0.97-2.39,1.9c-0.52,0.93-0.64,2-0.35,3.03c0.29,1.02,0.96,1.87,1.89,2.39
					c0.93,0.52,2.01,0.64,3.03,0.35c2.11-0.61,3.34-2.81,2.74-4.93l0,0C99.51,142.87,97.3,141.64,95.19,142.24z"
      />
      <path
        d="M20.05,138.49c0.37-1,0.33-2.08-0.11-3.05c-0.44-0.97-1.24-1.7-2.23-2.08c-1-0.37-2.08-0.33-3.05,0.11
					c-2,0.92-2.88,3.29-1.96,5.29c0.45,0.97,1.24,1.71,2.24,2.08c1,0.37,2.08,0.33,3.05-0.12c0.61-0.28,1.13-0.7,1.53-1.23
					C19.73,139.19,19.92,138.86,20.05,138.49z"
      />
      <path
        d="M30.27,130.43c-0.44-0.97-1.24-1.71-2.24-2.08c-1-0.37-2.08-0.33-3.05,0.11c-0.97,0.44-1.71,1.24-2.08,2.23
					c-0.37,1-0.33,2.08,0.11,3.05c0.92,2,3.29,2.88,5.29,1.96l0,0C30.3,134.79,31.18,132.42,30.27,130.43z"
      />
      <path
        d="M40.71,128.46c0.37-1,0.33-2.09-0.11-3.05c-0.44-0.97-1.24-1.7-2.24-2.08c-1-0.37-2.08-0.33-3.05,0.11
					c-2,0.92-2.88,3.29-1.96,5.29c0.44,0.97,1.24,1.71,2.24,2.08c1,0.37,2.08,0.33,3.05-0.12c0.61-0.28,1.13-0.7,1.53-1.23
					C40.39,129.16,40.57,128.83,40.71,128.46z"
      />
      <path
        d="M50.93,120.4c-0.44-0.97-1.24-1.71-2.24-2.08c-1-0.37-2.08-0.33-3.05,0.12c-0.97,0.44-1.71,1.23-2.08,2.23
					c-0.37,1-0.33,2.08,0.12,3.05c0.92,2,3.29,2.88,5.29,1.96l0,0C50.96,124.77,51.84,122.4,50.93,120.4z"
      />
      <path
        d="M37.86,100.85c-0.72,0.79-1.08,1.81-1.04,2.87c0.05,1.06,0.51,2.04,1.3,2.76c0.79,0.72,1.81,1.08,2.87,1.04
					c2.2-0.1,3.9-1.97,3.79-4.17c-0.05-1.06-0.51-2.05-1.3-2.76c-0.79-0.72-1.81-1.08-2.87-1.03c-0.67,0.03-1.31,0.23-1.88,0.57
					C38.42,100.32,38.12,100.56,37.86,100.85z"
      />
      <path
        d="M25.37,104.52c0.05,1.07,0.51,2.04,1.3,2.76c0.79,0.72,1.81,1.09,2.87,1.04c1.06-0.05,2.04-0.51,2.76-1.3
					c0.72-0.79,1.09-1.81,1.04-2.87c-0.11-2.2-1.98-3.9-4.17-3.79l0,0C26.97,100.46,25.27,102.32,25.37,104.52z"
      />
      <path
        d="M34.99,55.54c1.07-0.01,2.06-0.44,2.81-1.2c0.75-0.76,1.15-1.77,1.13-2.83c-0.01-1.07-0.44-2.06-1.2-2.81
					c-1.58-1.53-4.11-1.51-5.64,0.07c-0.74,0.77-1.15,1.77-1.13,2.84c0.01,1.06,0.44,2.06,1.2,2.8c0.48,0.47,1.07,0.8,1.7,0.98
					C34.21,55.49,34.6,55.55,34.99,55.54z"
      />
      <path
        d="M46.18,62.18c0.74-0.76,1.15-1.77,1.13-2.83c-0.01-1.07-0.44-2.06-1.2-2.8c-0.76-0.75-1.76-1.15-2.83-1.13
					c-1.06,0.01-2.06,0.44-2.81,1.2c-1.53,1.58-1.5,4.1,0.07,5.64l0,0C42.12,63.78,44.65,63.75,46.18,62.18z"
      />
      <path
        d="M51.77,71.21c1.06-0.01,2.06-0.44,2.81-1.2c0.74-0.76,1.15-1.77,1.13-2.83c-0.01-1.07-0.44-2.06-1.2-2.81
					c-1.57-1.53-4.1-1.5-5.64,0.07c-0.75,0.76-1.15,1.77-1.13,2.83c0.01,1.07,0.44,2.06,1.2,2.8c0.48,0.47,1.06,0.81,1.7,0.98
					C51,71.17,51.38,71.22,51.77,71.21z"
      />
      <path
        d="M86.92,46.29c-0.78-0.73-1.8-1.1-2.86-1.07c-1.06,0.04-2.05,0.49-2.78,1.27c-0.72,0.78-1.1,1.8-1.07,2.86
					c0.08,2.2,1.93,3.92,4.13,3.84c1.07-0.04,2.05-0.49,2.78-1.27c0.72-0.78,1.1-1.8,1.06-2.86c-0.02-0.67-0.21-1.32-0.55-1.88
					C87.45,46.86,87.21,46.56,86.92,46.29z"
      />
      <path
        d="M83.4,33.77c-1.07,0.04-2.05,0.49-2.78,1.27c-0.72,0.78-1.1,1.79-1.06,2.86c0.04,1.06,0.49,2.05,1.27,2.78
					c0.78,0.72,1.8,1.1,2.86,1.06c2.2-0.08,3.92-1.93,3.84-4.13v0C87.44,35.41,85.59,33.69,83.4,33.77z"
      />
      <path
        d="M124.82,59.64c-0.08-1.06-0.58-2.03-1.39-2.72c-0.81-0.69-1.84-1.03-2.9-0.94c-1.06,0.08-2.03,0.58-2.72,1.39
					c-1.43,1.67-1.23,4.19,0.45,5.62c0.81,0.69,1.84,1.03,2.91,0.94c1.06-0.09,2.03-0.58,2.72-1.39c0.44-0.51,0.73-1.11,0.87-1.76
					C124.82,60.42,124.85,60.03,124.82,59.64z"
      />
      <path
        d="M130.69,48.02c-0.81-0.69-1.84-1.02-2.91-0.94c-1.06,0.08-2.02,0.58-2.71,1.39c-0.69,0.81-1.03,1.84-0.94,2.9
					c0.09,1.06,0.58,2.03,1.39,2.72c1.67,1.42,4.2,1.22,5.62-0.45l0,0C132.56,51.97,132.36,49.45,130.69,48.02z"
      />
      <path
        d="M73.26,146.74c0.56,0.91,1.44,1.54,2.47,1.79c1.04,0.25,2.11,0.08,3.01-0.48c0.91-0.56,1.54-1.44,1.79-2.48
					c0.51-2.13-0.82-4.29-2.96-4.8c-1.04-0.25-2.11-0.07-3.02,0.48c-0.91,0.56-1.54,1.43-1.79,2.47c-0.15,0.65-0.14,1.33,0.03,1.96
					C72.9,146.06,73.06,146.41,73.26,146.74z"
      />
      <path
        d="M73.33,159.76c1.04,0.25,2.11,0.07,3.01-0.49c0.91-0.56,1.54-1.44,1.79-2.47c0.24-1.04,0.08-2.1-0.48-3.01
					c-0.56-0.9-1.44-1.54-2.47-1.79c-2.14-0.51-4.29,0.82-4.8,2.96l0,0C69.87,157.1,71.19,159.25,73.33,159.76z"
      />
      <path
        d="M68.45,169.19c0.56,0.91,1.44,1.54,2.48,1.79c1.03,0.25,2.1,0.08,3.01-0.48c0.91-0.56,1.54-1.44,1.79-2.48
					c0.51-2.13-0.82-4.29-2.96-4.8c-1.04-0.25-2.11-0.08-3.01,0.48c-0.91,0.56-1.54,1.44-1.78,2.48c-0.16,0.66-0.14,1.33,0.03,1.96
					C68.09,168.51,68.24,168.86,68.45,169.19z"
      />
    </svg>
  );
}

export default SpenlenLogo;
