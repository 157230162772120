import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { Box, Text } from "@chakra-ui/react";
import { Button, Form, SubmitButton } from "components/form/index.js";
import DancerFormFields from "components/appointment/DancerFormFields.js";

const messages = defineMessages({
  submitMessage: { id: "sign-up.button.thats-everyone" },
});

function AddDancersForm({ maxDancers, initialValues, onSubmit }) {
  const [dancerCount, setDancerCount] = useState(1);

  const handleAddDancerClick = () => {
    setDancerCount((count) => count + 1);
  };

  const dancerFields = [];
  for (let i = 1; i <= dancerCount; i++) {
    dancerFields.push(
      <Box key={i} borderWidth="1px" borderRadius="lg" p="4" mb="6">
        <DancerFormFields dancerNumber={i} />
      </Box>
    );
  }

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {dancerFields}
      {dancerCount < maxDancers && (
        <>
          <Text textAlign="center">
            <FormattedMessage id="sign-up.add-dancer-instructions" />
          </Text>
          <Text textAlign="center">
            <Button onClick={handleAddDancerClick}>
              <FormattedMessage id="sign-up.button.add-another-dancer" />
            </Button>
          </Text>
        </>
      )}
      <Text textAlign="center">
        <SubmitButton submitMessage={messages.submitMessage} />
      </Text>
    </Form>
  );
}

export default AddDancersForm;
