import { defineMessages } from "react-intl";

const messages = defineMessages({
  mixedDefault: { id: "yup.mixed.default" },
  mixedRequired: { id: "yup.mixed.required" },
  mixedOneOf: { id: "yup.mixed.one-of" },
  mixedNotOneOf: { id: "yup.mixed.not-one-of" },
  mixedNotType: { id: "yup.mixed.not-type" },
  mixedDefined: { id: "yup.mixed.defined" },
  stringLength: { id: "yup.string.length" },
  stringMin: { id: "yup.string.min" },
  stringMax: { id: "yup.string.max" },
  stringMatches: { id: "yup.string.matches" },
  stringEmail: { id: "yup.string.email" },
  stringUrl: { id: "yup.string.url" },
  stringTrim: { id: "yup.string.trim" },
  stringLowercase: { id: "yup.string.lowercase" },
  stringUppercase: { id: "yup.string.uppercase" },
  stringPhoneNumber: { id: "yup.string.phone-number" },
  numberTypeError: { id: "yup.number.type-error" },
  numberMin: { id: "yup.number.min" },
  numberMax: { id: "yup.number.max" },
  numberLessThan: { id: "yup.number.less-than" },
  numberMoreThan: { id: "yup.number.more-than" },
  numberNotEqual: { id: "yup.number.not-equal" },
  numberPositive: { id: "yup.number.positive" },
  numberNegative: { id: "yup.number.negative" },
  numberInteger: { id: "yup.number.integer" },
  booleanTypeError: { id: "yup.boolean.type-error" },
  dateTimeAfter: { id: "yup.datetime.after" },
  dateTimeOnOrAfter: { id: "yup.datetime.on-or-after" },
  dateTimeBefore: { id: "yup.datetime.before" },
  dateTimeOnOrBefore: { id: "yup.datetime.on-or-before" },
  dateTimeBetween: { id: "yup.datetime.between" },
  dateTimeBetweenInclusive: { id: "yup.datetime.between-inclusive" },
  objectNoUnknown: { id: "yup.object.no-unknown" },
  arrayMin: { id: "yup.array.min" },
  arrayMax: { id: "yup.array.max" },
});

/**
 * Returns a function that will yield a new message identifier object, appending
 * the values supplied by Yup in case of a schema validation failure. Allows for
 * localized error messages that must include the test parameters or user values.
 *
 * @param {{id: string}} identifier
 * @returns {function(*): {id: string, values: *}}
 */
export function localizedYupMessage(identifier) {
  return (values) => {
    return { ...identifier, values };
  };
}

export const mixed = {
  default: localizedYupMessage(messages.mixedDefault),
  required: localizedYupMessage(messages.mixedRequired),
  oneOf: localizedYupMessage(messages.mixedOneOf),
  notOneOf: localizedYupMessage(messages.mixedNotOneOf),
  notType: (values) => {
    switch (values.type) {
      case "number":
        return { ...messages.numberTypeError, values };
      case "boolean":
        return { ...messages.booleanTypeError, values };
      case "date":
        return { ...messages.dateTypeError, values };
      default:
        return {
          ...messages.mixedNotType,
          values: {
            path: values.path,
            type: values.type,
            value: JSON.stringify(values.value),
            originalValue: JSON.stringify(values.originalValue),
          },
        };
    }
  },
  defined: localizedYupMessage(messages.mixedDefined),
};

export const string = {
  length: localizedYupMessage(messages.stringLength),
  min: localizedYupMessage(messages.stringMin),
  max: localizedYupMessage(messages.stringMax),
  matches: localizedYupMessage(messages.stringMatches),
  email: localizedYupMessage(messages.stringEmail),
  url: localizedYupMessage(messages.stringUrl),
  trim: localizedYupMessage(messages.stringTrim),
  lowercase: localizedYupMessage(messages.stringLowercase),
  uppercase: localizedYupMessage(messages.stringUppercase),
  phoneNumber: localizedYupMessage(messages.stringPhoneNumber),
};

export const number = {
  min: localizedYupMessage(messages.numberMin),
  max: localizedYupMessage(messages.numberMax),
  lessThan: localizedYupMessage(messages.numberLessThan),
  moreThan: localizedYupMessage(messages.numberMoreThan),
  notEqual: localizedYupMessage(messages.numberNotEqual),
  positive: localizedYupMessage(messages.numberPositive),
  negative: localizedYupMessage(messages.numberNegative),
  integer: localizedYupMessage(messages.numberInteger),
};

export const datetime = {
  after: localizedYupMessage(messages.dateTimeAfter),
  onOrAfter: localizedYupMessage(messages.dateTimeOnOrAfter),
  before: localizedYupMessage(messages.dateTimeBefore),
  onOrBefore: localizedYupMessage(messages.dateTimeOnOrBefore),
  between: localizedYupMessage(messages.dateTimeBetween),
  betweenInclusive: localizedYupMessage(messages.dateTimeBetweenInclusive),
};

export const object = {
  noUnknown: localizedYupMessage(messages.objectNoUnknown),
};

export const array = {
  min: localizedYupMessage(messages.arrayMin),
  max: localizedYupMessage(messages.arrayMax),
};

/* Override Yup's built-in locale with messages suitable for form validation.
 * Note that if you want to use Yup in non-UI contexts and don't want these
 * form validation defaults, you may always provide your own `message` parameter
 * in the schema definitions.
 */
export const locale = {
  mixed,
  string,
  number,
  object,
  array,
};
