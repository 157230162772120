import { defineMessages } from "react-intl";
// import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import WhosNext from "components/session/WhosNext.js";
import CheckIn from "components/session/CheckIn.js";
import useSessionAppointments from "lib/appointments/useSessionAppointments.js";
import { useMemo } from "react";

const messages = defineMessages({
  title: { id: "session.title.kiosk" },
});

function Kiosk() {
  usePageTitle(messages.title);

  const { sessionUuid } = useParams();
  const response = useSessionAppointments(sessionUuid);

  const { checkedIn, pending } = useMemo(() => {
    const checkedIn = [];
    const pending = [];

    if (response.json?.appointments) {
      response.json.appointments
        // .filter((appointment) => {
        //   const appointmentDate = DateTime.fromISO(appointment.date);
        //   return appointmentDate.toFormat("yyyy-LL-dd") === params.date;
        // })
        .forEach((appointment) => {
          switch (appointment.status) {
            case "checkin":
            case "started":
            case "suspend":
            case "resumed":
              checkedIn.push(appointment);
              break;
            case "deppend":
            case "dapprcs":
            case "depdecl":
            case "deppaid":
            case "confirm":
              pending.push(appointment);
              break;
            default:
            // already finished!
          }
        });
    }

    return { checkedIn, pending };
  }, [response.json]);

  return (
    <Flex>
      <WhosNext appointments={checkedIn} />
      <CheckIn appointments={pending} />
    </Flex>
  );
}

export default Kiosk;
