import { createContext, forwardRef, useContext } from "react";
import { Formik, Form as FormikForm } from "formik";

const ValidationSchemaContext = createContext(undefined);

export function useValidationSchemaContext() {
  return useContext(ValidationSchemaContext);
}

const Form = forwardRef(function (
  { validationSchema, children, ...props },
  ref
) {
  return (
    <ValidationSchemaContext.Provider value={validationSchema}>
      <Formik validationSchema={validationSchema} {...props}>
        <FormikForm ref={ref} noValidate>
          {children}
        </FormikForm>
      </Formik>
    </ValidationSchemaContext.Provider>
  );
});
Form.displayName = "Form";

export default Form;
