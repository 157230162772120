import { FormattedMessage } from "react-intl";
import { Outlet } from "react-router-dom";
import { Box, Container, Heading } from "@chakra-ui/react";
import SiteFooter from "./SiteFooter.js";

function SignUpLayout() {
  return (
    <Box backgroundColor="gray.100" minHeight="100vh">
      <Container
        backgroundColor="white"
        borderColor="primary"
        borderLeftWidth={6}
        borderRightWidth={6}
        minHeight="100vh"
        display="flex"
        flexDirection="column"
      >
        <Heading as="h1" variant="site" textAlign="center" size="2xl">
          <FormattedMessage id="its-picture-day" />
        </Heading>
        <Box flex="1 0 auto">
          <Outlet />
        </Box>
        <SiteFooter />
      </Container>
    </Box>
  );
}

export default SignUpLayout;
