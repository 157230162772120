import { Fragment, useCallback, useMemo, useRef } from "react";
import { FormattedMessage, FormattedTime } from "react-intl";
import { DateTime } from "luxon";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";

function AppointmentBlock({ appointment }) {
  return (
    <>
      {appointment.dancers.map((dancer) => (
        <Fragment key={dancer.name}>
          <Text fontSize="4xl" lineHeight="1.1" fontWeight="600" m="0">
            {dancer.name}
          </Text>
          {dancer.costumes > 1 && (
            <Text fontSize="sm" m="0">
              <FormattedMessage
                id="dancer-costumes"
                values={{ count: dancer.costumes }}
              />
            </Text>
          )}
        </Fragment>
      ))}
      <Text mt="1" mb="0" fontSize="xs" color="blackAlpha.600">
        <FormattedTime value={DateTime.fromISO(appointment.date)} />
      </Text>
    </>
  );
}

function WhosNext({ appointments }) {
  const containerRef = useRef();
  const resetScrollTimer = useRef();

  const handleContainerScroll = useCallback(() => {
    clearTimeout(resetScrollTimer.current);
    resetScrollTimer.current = setTimeout(() => {
      containerRef.current.scrollTop = 0;
    }, 5000);
  }, []);

  const { current, suspended, others } = useMemo(() => {
    let current = null;
    const suspended = [];
    const others = [];
    for (const appointment of appointments) {
      switch (appointment.status) {
        case "started":
        case "resumed":
          current = appointment;
          break;
        case "suspend":
          suspended.push(appointment);
          break;
        default:
          others.push(appointment);
      }
    }
    return { current, suspended, others };
  }, [appointments]);

  return (
    <Flex w="40%" bgColor="primary" direction="column" h="100vh">
      <Heading
        as="h1"
        variant="site"
        textAlign="center"
        size="xl"
        m="0"
        p="3"
        color="white"
      >
        Who’s Next?
      </Heading>
      <Box overflowY="auto" onScroll={handleContainerScroll} ref={containerRef}>
        {current && (
          <Box
            p="4"
            bgColor="white"
            border="4px"
            borderColor="secondary"
            textAlign="center"
          >
            <AppointmentBlock appointment={current} />
          </Box>
        )}
        {!current && suspended.length < 1 && others.length < 1 && (
          <Flex direction="column" justifyContent="center" h="80vh">
            <Text textAlign="center" fontSize="xl" color="white">
              Will it be you?
            </Text>
            <Text textAlign="center" fontSize="xl" color="white">
              Check in here →
            </Text>
          </Flex>
        )}
        {suspended.map((appointment) => (
          <Box
            key={appointment.appointmentUuid}
            p="4"
            bgColor="white"
            borderBottom="4px"
            borderBottomColor="secondary"
            textAlign="center"
            color="gray.400"
          >
            <AppointmentBlock appointment={appointment} />
          </Box>
        ))}
        {others.map((appointment, index) => (
          <Box
            key={appointment.appointmentUuid}
            p="4"
            bgColor={`whiteAlpha.${Math.max(900 - index * 100, 400)}`}
            borderTop="1px"
            borderTopColor="white"
            textAlign="center"
          >
            <AppointmentBlock appointment={appointment} />
          </Box>
        ))}
      </Box>
    </Flex>
  );
}

export default WhosNext;
