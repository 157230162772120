import { useEffect, useMemo } from "react";
import { defineMessages, FormattedNumber } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Heading, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointment from "lib/appointments/useAppointment.js";
import useAppointmentPhotos from "lib/appointments/useAppointmentPhotos.js";
import useSessionProducts from "lib/session/useSessionProducts.js";
import useOrder from "lib/order/useOrder.js";
import useAddOrderItems from "lib/order/useAddOrderItems.js";
import { Form, SubmitCancelButtonGroup } from "components/form/index.js";
import Loading from "components/layout/Loading.js";

const messages = defineMessages({
  title: { id: "order.title" },
});

function PackageField({ products }) {
  const packageProducts = useMemo(() => {
    const packageProducts = [];
    if (products) {
      for (const product of products) {
        if (product.category === "packages") {
          packageProducts.push(product);
        }
      }
    }
    return packageProducts;
  }, [products]);

  const [{ value }, , { setValue }] = useField("sku");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <RadioGroup name="sku" size="lg" value={value} onChange={handleChange}>
      <Stack>
        {packageProducts.map((product) => (
          <Radio key={product.sku} value={product.sku}>
            {product.name} —{" "}
            <FormattedNumber
              value={product.unitPrice}
              style="currency"
              currency="USD"
            />{" "}
            — {product.description} &nbsp;
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
}

function PhotoField({ appointmentUuid, photos }) {
  const likedPhotos = useMemo(() => {
    const likedPhotos = [];
    if (photos) {
      for (const photo of photos) {
        if (photo.reviewStatus === "like") {
          likedPhotos.push(photo);
        }
      }
    }
    return likedPhotos;
  }, [photos]);

  const [{ value }, , { setValue }] = useField("photoFilename");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box mt="5">
      <Heading as="h3" size="md">
        Use This Picture:
      </Heading>
      <RadioGroup
        name="photoFilename"
        size="lg"
        value={value}
        onChange={handleChange}
      >
        <Stack>
          {likedPhotos.map((photo) => (
            <Radio key={photo.filename} value={photo.filename}>
              <Box
                as="span"
                backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
                  appointmentUuid
                )}/${encodeURIComponent(photo.filename)}`}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                height="200px"
                width="200px"
                display="inline-block"
              />
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </Box>
  );
}

function BuildYourOwnInstructions() {
  return (
    <Box mt="5">
      <Heading as="h3" size="md">
        Build Your Own! Instructions:
      </Heading>
      <Text>
        Pick any three of the “Extra Prints” on the next step to mix-and-match
        your own custom package.
      </Text>
    </Box>
  );
}

function PhotoBlock({ appointmentUuid, photos }) {
  const { values } = useFormikContext();

  if (values.sku === "pckg22e") {
    return <BuildYourOwnInstructions />;
  }
  return <PhotoField appointmentUuid={appointmentUuid} photos={photos} />;
}

function PackageForm({ appointmentUuid, products, order, items, photos }) {
  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    for (const item of items) {
      if (item.category === "packages") {
        return {
          sku: item.sku,
          photoFilename: item.photoFilename,
        };
      }
    }
    return {
      sku: "pckg22a",
      photoFilename: "",
    };
  }, [items]);

  const [addItems, addItemsResponse] = useAddOrderItems(
    appointmentUuid,
    order.orderNumber
  );

  useEffect(() => {
    if (addItemsResponse.status === 200) {
      navigate("..");
    }
  }, [navigate, addItemsResponse]);

  const handleSubmit = ({ sku, photoFilename }, formikProps) => {
    if (sku && (photoFilename || sku === "pckg22e")) {
      addItems([{ sku, quantity: 1, photoFilename }]);
    } else {
      formikProps.setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate("..");
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <PackageField products={products} />
      <PhotoBlock appointmentUuid={appointmentUuid} photos={photos} />
      <SubmitCancelButtonGroup onCancel={handleCancel} />
    </Form>
  );
}

function PackageProducts({ appointment, photos, order, items }) {
  const productsResponse = useSessionProducts(appointment.sessionUuid);

  if (!productsResponse.json) {
    return <Loading />;
  }

  if (productsResponse.status !== 200) {
    return <Text>Not found</Text>;
  }

  return (
    <>
      <Heading textAlign="center">Choose Your Package</Heading>
      <Text>
        Choose your base package and the picture you’d like to use. You can
        order add-ons, such as other pictures or additional prints for this
        picture, on the next step:
      </Text>
      <PackageForm
        appointmentUuid={appointment.appointmentUuid}
        products={productsResponse.json.products}
        order={order}
        items={items}
        photos={photos}
      />
    </>
  );
}

function Package() {
  usePageTitle(messages.title);

  const params = useParams();

  const appointmentResponse = useAppointment(params.appointmentUuid);
  const photosResponse = useAppointmentPhotos(params.appointmentUuid);
  const orderResponse = useOrder(params.appointmentUuid, params.orderNumber);

  if (
    !appointmentResponse.json ||
    !photosResponse.json ||
    !orderResponse.json
  ) {
    return <Loading />;
  }

  if (
    appointmentResponse.status !== 200 ||
    photosResponse.status !== 200 ||
    orderResponse.status !== 200
  ) {
    return <Text>Not found</Text>;
  }

  return (
    <PackageProducts
      appointment={appointmentResponse.json.appointment}
      photos={photosResponse.json.photos}
      order={orderResponse.json.order}
      items={orderResponse.json.items}
    />
  );
}

export default Package;
