import { useMemo } from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function PlaceAppointmentOrder({ appointment, items, photos }) {
  const names = useMemo(() => {
    const names = [];
    for (const dancer of appointment.dancers) {
      names.push(dancer.name);
    }
    return names;
  }, [appointment.dancers]);

  const step = useMemo(() => {
    for (const photo of photos) {
      if (photo.reviewStatus === "none") {
        return 1;
      }
    }

    let hasPackage = false;
    for (const item of items) {
      if (item.category === "packages") {
        hasPackage = true;
        break;
      }
    }
    if (!hasPackage) {
      return 2;
    }

    let hasAddOn = false;
    for (const item of items) {
      if (item.category !== "packages") {
        hasAddOn = true;
        break;
      }
    }
    if (!hasAddOn) {
      return 3;
    }

    return 4;
  }, [items, photos]);

  return (
    <>
      <Heading textAlign="center">
        <FormattedMessage id="order.title" />
      </Heading>
      <Text textAlign="center">
        Welcome back! Order the pictures for {names.join(" and ")} in four quick
        steps:
      </Text>
      <Text textAlign="center" my="8">
        <Button
          as={Link}
          to="select-pictures"
          size="lg"
          variant={step === 1 ? "solid" : "outline"}
          colorScheme={step === 1 ? "blue" : "gray"}
          boxShadow="base"
        >
          1. Select Your Pictures
        </Button>
      </Text>
      <Text textAlign="center" my="8">
        <Button
          as={step < 2 ? undefined : Link}
          to="package"
          size="lg"
          variant={step === 2 ? "solid" : "outline"}
          colorScheme={step === 2 ? "blue" : "gray"}
          boxShadow="base"
          disabled={step < 2}
        >
          2. Choose Your Package
        </Button>
      </Text>
      <Text textAlign="center" my="8">
        <Button
          as={step < 3 ? undefined : Link}
          to="add-pictures"
          size="lg"
          variant={step === 3 ? "solid" : "outline"}
          colorScheme={step === 3 ? "blue" : "gray"}
          boxShadow="base"
          disabled={step < 3}
        >
          3. Pick Add-Ons
        </Button>
      </Text>
      <Text textAlign="center" my="8">
        <Button
          as={step < 3 ? undefined : Link}
          to="confirm"
          size="lg"
          variant={step === 4 ? "solid" : "outline"}
          colorScheme={step === 4 ? "blue" : "gray"}
          boxShadow="base"
          disabled={step < 3}
        >
          4. Confirm and Submit
        </Button>
      </Text>
    </>
  );
}

export default PlaceAppointmentOrder;
