import { useEffect } from "react";
import { defineMessages } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Center, Heading, Text } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointment from "lib/appointments/useAppointment.js";
import useAppointmentPhotos from "lib/appointments/useAppointmentPhotos.js";
import useOrder from "lib/order/useOrder.js";
import useConfirmOrder from "lib/order/useConfirmOrder.js";
import { Form, SubmitCancelButtonGroup } from "components/form/index.js";
import OrderSummary from "components/order/OrderSummary.js";
import Loading from "components/layout/Loading.js";

const messages = defineMessages({
  title: { id: "order.title" },
  checkout: { id: "order.checkout" },
});

function PhotosList({ appointment, order, items, photos }) {
  const navigate = useNavigate();

  const [confirmOrder, response] = useConfirmOrder(appointment, order);

  useEffect(() => {
    if (response.status === 200) {
      if (response.json.checkoutUrl) {
        window.location = response.json.checkoutUrl;
      } else {
        navigate("..");
      }
    }
  }, [navigate, response]);

  const handleSubmit = () => {
    confirmOrder();
  };

  const handleCancel = () => {
    navigate("..");
  };

  return (
    <>
      <OrderSummary
        appointment={appointment}
        order={order}
        items={items}
        photos={photos}
      />
      <Form initialValues={{}} onSubmit={handleSubmit}>
        <Center>
          <SubmitCancelButtonGroup
            submitMessage={messages.checkout}
            onCancel={handleCancel}
          />
        </Center>
      </Form>
    </>
  );
}

function Confirm() {
  usePageTitle(messages.title);

  const params = useParams();

  const appointmentResponse = useAppointment(params.appointmentUuid);
  const photosResponse = useAppointmentPhotos(params.appointmentUuid);
  const orderResponse = useOrder(params.appointmentUuid, params.orderNumber);

  if (
    !appointmentResponse.json ||
    !photosResponse.json ||
    !orderResponse.json
  ) {
    return <Loading />;
  }

  if (
    appointmentResponse.status !== 200 ||
    photosResponse.status !== 200 ||
    orderResponse.status !== 200
  ) {
    return <Text>Not found</Text>;
  }

  return (
    <>
      <Heading textAlign="center">Confirm Your Order</Heading>
      <Text>
        Please confirm that your selections are correct — once your order has
        been submitted to the printer, it cannot be changed!
      </Text>
      <Text>
        If you need to make any changes, use your browser’s back button to
        return to a previous step. If everything looks correct, press the
        “Checkout” button at the bottom of this page to submit your order!
      </Text>
      <PhotosList
        appointment={appointmentResponse.json.appointment}
        order={orderResponse.json.order}
        items={orderResponse.json.items}
        photos={photosResponse.json.photos}
      />
    </>
  );
}

export default Confirm;
