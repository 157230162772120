import { defineMessages, FormattedMessage } from "react-intl";
import { Heading, Text } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";

const messages = defineMessages({
  title: { id: "order.title" },
});

function Order() {
  usePageTitle(messages.title);

  return (
    <>
      <Heading textAlign="center">
        <FormattedMessage id="order.title" />
      </Heading>
      <Text textAlign="center">
        Please check your email for the link to order your pictures.
      </Text>
      <Text textAlign="center">
        If you did not receive an order link, please email{" "}
        <a href="mailto:help@itspictureday.com">help@itspictureday.com</a> for
        assistance.
      </Text>
    </>
  );
}

export default Order;
