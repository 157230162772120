import { forwardRef } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import Button from "./Button.js";

const messages = defineMessages({
  cancel: { id: "button.cancel" },
});

const CancelButton = forwardRef(function (
  {
    message = messages.cancel,
    colorScheme = "gray",
    disabled = false,
    ...props
  },
  ref
) {
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      ref={ref}
      type="button"
      colorScheme={colorScheme}
      disabled={isSubmitting || disabled}
      {...props}
    >
      <FormattedMessage {...message} />
    </Button>
  );
});
CancelButton.displayName = "CancelButton";

export default CancelButton;
