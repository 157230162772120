import { useState, useLayoutEffect } from "react";
import { split } from "lodash";
import { Settings } from "luxon";
import "date-time-format-timezone";
import "@formatjs/intl-getcanonicallocales/polyfill.js";
import "@formatjs/intl-locale/polyfill.js";
import "@formatjs/intl-pluralrules/polyfill.js";
import "@formatjs/intl-pluralrules/locale-data/en.js";
import "@formatjs/intl-relativetimeformat/polyfill.js";
import "@formatjs/intl-relativetimeformat/locale-data/en.js";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import en from "messages/en.json";

function IntlProvider({ children }) {
  const [messages, setMessages] = useState(en);

  const [locale] = split(navigator.language, "-", 1); // en-US >>> en

  useLayoutEffect(() => {
    switch (locale) {
      case "es-disabled":
        import(`../messages/${locale}.json`).then((module) => {
          Settings.defaultLocale = locale;
          setMessages(module.default);
        });
        break;
      case "en":
      default:
        Settings.defaultLocale = locale;
        setMessages(en);
    }
  }, [locale]);

  return (
    <ReactIntlProvider locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  );
}

export default IntlProvider;
