import { defineMessages } from "react-intl";
import { Box } from "@chakra-ui/react";
import * as yup from "lib/yup/index.js";
import {
  EmailField,
  Form,
  SubmitCancelButtonGroup,
  TextField,
} from "components/form/index.js";

const messages = defineMessages({
  nameLabel: { id: "sign-up.field.name.name" },
  namePlaceholder: { id: "sign-up.field.name.placeholder" },
  emailLabel: { id: "sign-up.field.email.name" },
  emailPlaceholder: { id: "sign-up.field.email.placeholder" },
});

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
});

function CreateGroupOrderForm({ initialValues, onSubmit, onCancel }) {
  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <TextField
        label={messages.nameLabel}
        name="name"
        placeholder={messages.namePlaceholder}
        mb="2"
      />
      <EmailField
        label={messages.emailLabel}
        name="email"
        placeholder={messages.emailPlaceholder}
        mb="8"
      />
      <Box textAlign="center">
        <SubmitCancelButtonGroup onCancel={onCancel} />
      </Box>
    </Form>
  );
}

export default CreateGroupOrderForm;
