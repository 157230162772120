import { forwardRef } from "react";
import { useIntl } from "react-intl";
import { useField } from "formik";
import { Button, HStack, Input, useNumberInput } from "@chakra-ui/react";
import FormControl from "../FormControl.js";

const NumberSpinnerField = forwardRef(function (
  {
    id,
    name,
    label,
    placeholder,
    step = 1,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    help,
    autoFocus = false,
    disabled = false,
    ...props
  },
  ref
) {
  const intl = useIntl();

  const [{ value, onBlur, onChange }, , { setValue }] = useField(name);

  const handleIncrementClick = () => {
    setValue(Math.min((parseInt(value, 10) || 0) + step, max));
  };
  const handleDecrementClick = () => {
    setValue(Math.max((parseInt(value, 10) || 0) - step, min));
  };

  return (
    <FormControl
      id={id}
      name={name}
      label={label}
      help={help}
      isDisabled={disabled}
      {...props}
    >
      <HStack maxW="10em">
        <Button
          type="button"
          tabIndex="-1"
          onClick={handleIncrementClick}
          fontSize="2em"
        >
          +
        </Button>
        <Input
          ref={ref}
          type="text"
          inputMode="decimal"
          pattern="[0-9]*(.[0-9]+)?"
          role="spinbutton"
          name={name}
          placeholder={
            placeholder && intl.formatMessage(placeholder, placeholder.values)
          }
          value={value ?? ""}
          autoFocus={autoFocus}
          onBlur={onBlur}
          onChange={onChange}
          // aria-errormessage={isInvalid ? `${id}-error` : undefined}
          // aria-labelledby={label && `${id}-label`}
          // aria-describedby={help && `${id}-help`}
          fontSize="1.5em"
          textAlign="center"
        />
        <Button
          type="button"
          tabIndex="-1"
          onClick={handleDecrementClick}
          fontSize="2em"
        >
          -
        </Button>
      </HStack>
    </FormControl>
  );
});
NumberSpinnerField.displayName = "NumberSpinnerField";

export default NumberSpinnerField;
