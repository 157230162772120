import { Box, Text } from "@chakra-ui/react";
import useAppointmentBlocks from "lib/appointments/useAppointmentBlocks.js";
import Loading from "components/layout/Loading.js";
import AvailableAppointmentBlockButton from "./AvailableAppointmentBlockButton.js";
import FullAppointmentBlockButton from "./FullAppointmentBlockButton.js";

function AppointmentBlockButtonList({ sessionUuid }) {
  const blocks = useAppointmentBlocks(sessionUuid);

  if (!blocks) {
    return <Loading />;
  }

  return (
    <Box textAlign="center">
      {blocks.length < 1 && (
        <Text fontWeight="600" fontSize="lg">
          There are no more available appointments!
        </Text>
      )}
      {blocks.map((block) => (
        <Text textAlign="center" key={block.date}>
          {block.remaining > 0 ? (
            <AvailableAppointmentBlockButton
              block={block}
              to={block.date}
              size="lg"
              padding="8"
            />
          ) : (
            <FullAppointmentBlockButton block={block} size="lg" padding="8" />
          )}
        </Text>
      ))}
      {blocks.length < 3 && (
        <Text>
          Walk-ins may still be able to be accommodated.
          <br />
          Please contact your dance studio for more information.
        </Text>
      )}
    </Box>
  );
}

export default AppointmentBlockButtonList;
