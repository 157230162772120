import { useCallback, useState } from "react";
import useJsonApi from "lib/useJsonApi.js";

function useReviewPhoto(appointment, filename) {
  const [request, setRequest] = useState({});

  const response = useJsonApi({
    url: request.url,
    method: "POST",
    body: request.body,
  });

  const review = useCallback(
    (status) => {
      setRequest({
        url: `/api/appointment/${encodeURIComponent(
          appointment.appointmentUuid
        )}/review-photo/${encodeURIComponent(filename)}`,
        body: { status },
      });
    },
    [appointment, filename]
  );

  return [review, response];
}

export default useReviewPhoto;
