import { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  title: { id: "page-title" },
});

function usePageTitle(descriptor, values) {
  const intl = useIntl();
  const title = intl.formatMessage(descriptor, values || descriptor.values);

  useEffect(() => {
    document.title = intl.formatMessage(messages.title, { title });
  }, [intl, title]);
  return null;
}

export default usePageTitle;
