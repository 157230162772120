import useUpcomingSessions from "lib/session/useUpcomingSessions.js";
import Loading from "components/layout/Loading.js";
import UpcomingSessionButton from "./UpcomingSessionButton.js";

function UpcomingSessionsButtonList() {
  const sessionsResponse = useUpcomingSessions();

  if (!sessionsResponse.json) {
    return <Loading />;
  }

  return sessionsResponse.json.sessions.map((session) => (
    <UpcomingSessionButton key={session.sessionUuid} session={session} />
  ));
}

export default UpcomingSessionsButtonList;
