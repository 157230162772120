import { Route, Routes } from "react-router-dom";
import SignUpLayout from "components/layout/SignUpLayout.js";
import SessionLayout from "components/layout/sign-up/SessionLayout.js";
import SignUp from "pages/SignUp.js";
import Session from "pages/sign-up/Session.js";
import Appointment from "pages/sign-up/organization/Appointment.js";
import Confirm from "pages/sign-up/organization/Confirm.js";
import Confirmed from "pages/sign-up/organization/Confirmed.js";

function SignUpRoutes() {
  return (
    <Routes caseSensitive={true}>
      <Route path="/" element={<SignUpLayout />}>
        <Route index={true} element={<SignUp />} />
        <Route path=":sessionUuid" element={<SessionLayout />}>
          <Route index element={<Session />} />
          <Route path=":date" element={<Appointment />} />
          <Route path="confirm/:appointmentUuid" element={<Confirm />} />
          <Route path="confirmed/:appointmentUuid" element={<Confirmed />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default SignUpRoutes;
