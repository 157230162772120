import { Navigate, Route, Routes } from "react-router-dom";
import OrderRoutes from "./routes/OrderRoutes.js";
import SessionRoutes from "./routes/SessionRoutes.js";
import SignUpRoutes from "./routes/SignUpRoutes.js";
import FormTest from "pages/FormTest.js";

function AppRoutes() {
  return (
    <Routes caseSensitive={true}>
      <Route path="/" element={<Navigate replace to="/sign-up" />} />
      <Route path="/order/*" element={<OrderRoutes />} />
      <Route path="/session/*" element={<SessionRoutes />} />
      <Route path="/sign-up/*" element={<SignUpRoutes />} />
      <Route path="/form-test" element={<FormTest />} />
    </Routes>
  );
}

export default AppRoutes;
