import { defineMessages, FormattedNumber } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { Heading, Text, Box, Flex } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useSessionOrderSummaries from "lib/session/useSessionOrderSummaries.js";
import Loading from "components/layout/Loading.js";

const messages = defineMessages({
  title: { id: "session.title.manage" },
});

function PhotoBlock({ appointment, photos }) {
  if (photos.length < 1) {
    return null;
  }

  const photoFilename = photos[0].photoFilename;

  return (
    <Flex py="3" alignItems="center" direction={{ base: "column", sm: "row" }}>
      <Box
        backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
          appointment.appointmentUuid
        )}/${encodeURIComponent(photoFilename)}`}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        height="250px"
        width="250px"
      />
      <Box pt={{ base: "5", sm: "0" }} pl={{ base: "0", sm: "5" }}>
        <Heading size="sm">{photoFilename}</Heading>
        {photos.map((item) => (
          <Text key={item.sku}>
            <FormattedNumber
              value={item.unitPrice * item.quantity}
              style="currency"
              currency="USD"
            />{" "}
            ({item.quantity}) — {item.name}
          </Text>
        ))}
      </Box>
    </Flex>
  );
}

function AppointmentBlock({ appointment, photos }) {
  const dancers = appointment.dancers
    ?.map((dancer) => dancer.name)
    .join(" and ");
  return (
    <Box px="3" py="5" borderBottom="1px" borderBottomColor="gray.500">
      <Heading size="md">
        <Link
          to={`/order/${appointment.appointmentUuid}/${appointment.orderNumber}`}
        >
          {appointment.orderNumber}
        </Link>
        {dancers && ` — ${dancers}`}
      </Heading>
      <Text>
        <FormattedNumber
          value={appointment.itemsTotal}
          style="currency"
          currency="USD"
        />{" "}
        - {appointment.contactName} &lt;
        {appointment.contactEmail}&gt; -{" "}
        <Link
          to={`/order/${appointment.appointmentUuid}/${appointment.orderNumber}/packing-slip`}
        >
          Packing Slip
        </Link>
      </Text>
      {Object.entries(photos).map(([photoFilename, photos]) => (
        <PhotoBlock
          key={photoFilename}
          appointment={appointment}
          photos={photos}
        />
      ))}
    </Box>
  );
}

function OrderSummaries() {
  usePageTitle(messages.title);

  const { sessionUuid } = useParams();
  const ordersResponse = useSessionOrderSummaries(sessionUuid);

  if (!ordersResponse.json) {
    return <Loading />;
  }

  return (
    <>
      <Heading size="lg" textAlign="center">
        Order Summaries
      </Heading>
      {Object.entries(ordersResponse.json.orders).map(
        ([orderNumber, order]) => (
          <AppointmentBlock
            key={orderNumber}
            appointment={order.appointment}
            photos={order.photos}
          />
        )
      )}
    </>
  );
}

export default OrderSummaries;
