import { FormattedMessage } from "react-intl";
import { Button } from "@chakra-ui/react";
import AppointmentBlockLabel from "./AppointmentBlockLabel.js";

function FullAppointmentBlockButton({ block, ...params }) {
  return (
    <Button disabled={true} fontWeight="400" {...params}>
      <AppointmentBlockLabel block={block} />
      <br />
      <FormattedMessage id="appointment-block.full" />
    </Button>
  );
}

export default FullAppointmentBlockButton;
