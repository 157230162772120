import { forwardRef } from "react";
import { useFormikContext } from "formik";
import { Button as ChakraButton } from "@chakra-ui/react";

const Button = forwardRef(function (
  { type = "button", disabled = false, ...props },
  ref
) {
  const { isSubmitting } = useFormikContext();

  return (
    <ChakraButton
      ref={ref}
      type={type}
      disabled={isSubmitting || disabled}
      {...props}
    />
  );
});
Button.displayName = "Button";

export default Button;
