import { useCallback, useState } from "react";
import useJsonApi from "lib/useJsonApi.js";

function useConfirmAppointment(appointment) {
  const [request, setRequest] = useState({});

  const response = useJsonApi({
    url: request.url,
    method: "POST",
    body: request.body,
  });

  const confirmAppointment = useCallback(
    (name, email, method) => {
      setRequest({
        url: `/api/appointment/${encodeURIComponent(
          appointment.appointmentUuid
        )}/confirm`,
        body: { name, email, method },
      });
    },
    [appointment]
  );

  return [confirmAppointment, response];
}

export default useConfirmAppointment;
