import { defineMessages, FormattedMessage } from "react-intl";
import { useHref, useParams } from "react-router-dom";
import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointment from "lib/appointments/useAppointment.js";
import useAppointmentPhotos from "lib/appointments/useAppointmentPhotos.js";
import useOrder from "lib/order/useOrder.js";
import Loading from "components/layout/Loading.js";
import SiteFooter from "components/layout/SiteFooter.js";
import { useMemo } from "react";

const messages = defineMessages({
  title: { id: "order.title" },
});

function PhotoBlock({ appointment, items, photo }) {
  const photoItems = useMemo(() => {
    return items.filter((item) => {
      return item.photoFilename === photo.filename;
    });
  }, [items, photo]);

  if (photoItems.length < 1) {
    return null;
  }

  return (
    <WrapItem width="200px" flexDirection="column" alignItems="center">
      <Box
        backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
          appointment.appointmentUuid
        )}/${encodeURIComponent(photo.filename)}`}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        height="120px"
        width="120px"
      />
      {photo.title && (
        <Heading as="h3" size="sm" textAlign="center" mb="0">
          {photo.title}
        </Heading>
      )}
      <Box p="2">
        {photoItems.map((item) => (
          <Text key={item.sku}>
            ({item.quantity}) — {item.name}
          </Text>
        ))}
      </Box>
    </WrapItem>
  );
}

function PackingSlipDetail({ appointment, order, items, photos }) {
  const { likedPhotos, hasDownload } = useMemo(() => {
    const likedPhotos = [];
    if (photos) {
      for (const photo of photos) {
        if (photo.reviewStatus === "like") {
          likedPhotos.push(photo);
        } else {
          for (const item of items) {
            if (
              item.photoFilename === photo.filename ||
              item.photoFilename === photo.filename
            ) {
              likedPhotos.push(photo);
              break;
            }
          }
        }
      }
    }
    let hasDownload = false;
    for (const item of items) {
      if (
        item.sku === "dnld22" ||
        item.sku === "pckg22a" ||
        item.sku === "pckg22d"
      ) {
        hasDownload = true;
        break;
      }
    }
    return { likedPhotos, hasDownload };
  }, [items, photos]);

  const orderUrl =
    "https://itspictureday.com" + useHref(".").replace("/packing-slip", "");

  const dancers = appointment.dancers
    ?.map((dancer) => dancer.name)
    .join(" and ");

  return (
    <>
      <Heading mb="1" textAlign="center">
        {dancers}
      </Heading>
      <Heading as="h3" size="sm" textAlign="center" mb="10">
        Order# {order.orderNumber} — {order.contactName} &lt;
        {order.contactEmail}&gt;
      </Heading>
      <Center>
        <Wrap>
          {likedPhotos.map((photo) => (
            <PhotoBlock
              key={photo.filename}
              appointment={appointment}
              items={items}
              photo={photo}
            />
          ))}
        </Wrap>
      </Center>
      {hasDownload && (
        <Center>
          <Flex alignItems="center">
            <Box>
              <Text fontSize="sm">
                To download your digital files, scan the QR code at right or
                visit the following page:
              </Text>
              <Text fontSize="xs" mb="0">
                {orderUrl}
              </Text>
            </Box>
            <img
              src={`https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=${orderUrl}`}
              alt="QR Code"
            />
          </Flex>
        </Center>
      )}
      <Text fontSize="sm">
        Thank you for your order! If you have any questions or there are any
        problems with your items, please don't hesitate to contact us at:{" "}
        <strong>help@itspictureday.com</strong>
      </Text>
    </>
  );
}

function PackingSlip() {
  usePageTitle(messages.title);

  const params = useParams();

  const appointmentResponse = useAppointment(params.appointmentUuid);
  const photosResponse = useAppointmentPhotos(params.appointmentUuid);
  const orderResponse = useOrder(params.appointmentUuid, params.orderNumber);

  if (
    !appointmentResponse.json ||
    !photosResponse.json ||
    !orderResponse.json
  ) {
    return <Loading />;
  }

  if (
    appointmentResponse.status !== 200 ||
    photosResponse.status !== 200 ||
    orderResponse.status !== 200
  ) {
    return <Text>Not found</Text>;
  }

  return (
    <>
      <Heading as="h1" variant="site" textAlign="center" size="2xl">
        <FormattedMessage id="its-picture-day" />
      </Heading>
      <PackingSlipDetail
        appointment={appointmentResponse.json.appointment}
        order={orderResponse.json.order}
        items={orderResponse.json.items}
        photos={photosResponse.json.photos}
      />
      <SiteFooter pt="3" pb="0" />
    </>
  );
}

export default PackingSlip;
