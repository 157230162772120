import { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import {
  FormLabel as ChakraFormLabel,
  RequiredIndicator as ChakraRequiredIndicator,
} from "@chakra-ui/react";

function RequiredIndicator() {
  return (
    <ChakraRequiredIndicator>
      <FormattedMessage
        id="form.field-required-indicator"
        defaultMessage=" (required)"
      />
    </ChakraRequiredIndicator>
  );
}

const FormLabel = forwardRef(function ({ label, ...props }, ref) {
  if (!label) {
    return null;
  }
  return (
    <ChakraFormLabel
      ref={ref}
      requiredIndicator={<RequiredIndicator />}
      {...props}
    >
      <FormattedMessage {...label} />
    </ChakraFormLabel>
  );
});
FormLabel.displayName = "FormLabel";

export default FormLabel;
