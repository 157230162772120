import { useEffect, useMemo } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { wrapNonLocalizedMessage } from "lib/intl/index.js";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointment from "lib/appointments/useAppointment.js";
import useAppointmentPhotos from "lib/appointments/useAppointmentPhotos.js";
import useAppointmentPhotoProducts from "lib/appointments/useAppointmentPhotoProducts.js";
import useOrder from "lib/order/useOrder.js";
import useAddOrderItems from "lib/order/useAddOrderItems.js";
import {
  Form,
  NumberSpinnerField,
  SubmitCancelButtonGroup,
} from "components/form/index.js";
import Loading from "components/layout/Loading.js";

const messages = defineMessages({
  title: { id: "order.add-this-picture" },
  quantityPlaceholder: { id: "order.field.quantity.placeholder" },
});

function AddPictureForm({
  appointmentUuid,
  products,
  order,
  items,
  photoFilename,
}) {
  const navigate = useNavigate();

  const nonPackageProducts = useMemo(() => {
    const nonPackageProducts = [];
    if (products) {
      for (const product of products) {
        if (product.category !== "packages") {
          nonPackageProducts.push(product);
        }
      }
    }
    return nonPackageProducts;
  }, [products]);

  const initialValues = useMemo(() => {
    return nonPackageProducts.reduce((prev, product) => {
      let quantity = "";
      for (const item of items) {
        if (item.sku === product.sku && item.photoFilename === photoFilename) {
          quantity = item.quantity.toString();
          break;
        }
      }
      return {
        [product.sku]: quantity,
        ...prev,
      };
    }, {});
  }, [nonPackageProducts, items, photoFilename]);

  const [addItems, addItemsResponse] = useAddOrderItems(
    appointmentUuid,
    order.orderNumber
  );

  useEffect(() => {
    if (addItemsResponse.status === 200) {
      navigate("..", { state: { photoFilename } });
    }
  }, [navigate, photoFilename, addItemsResponse]);

  const handleSubmit = (values, formikProps) => {
    const items = nonPackageProducts.map((product) => {
      return {
        sku: product.sku,
        quantity: values[product.sku] ? parseInt(values[product.sku], 10) : 0,
        photoFilename,
      };
    });
    addItems(items);
  };

  const handleCancel = () => {
    navigate("..", { state: { photoFilename } });
  };

  const productFields = [];
  let lastCategory = "";
  for (const product of nonPackageProducts) {
    if (lastCategory !== product.category) {
      productFields.push(
        <Heading
          key={product.category}
          as="h3"
          size="md"
          mt={lastCategory === "" ? undefined : 5}
        >
          {product.categoryName}
        </Heading>
      );
      lastCategory = product.category;
    }
    productFields.push(
      <Box
        key={product.sku}
        backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/products/${encodeURIComponent(
          product.sku
        )}.png`}
        backgroundSize="50px"
        backgroundRepeat="no-repeat"
        backgroundPosition="left center"
        pl="68px"
        pb={2}
        mb={2}
        borderBottom="1px solid #ccc"
        minHeight="50px"
      >
        <NumberSpinnerField
          label={wrapNonLocalizedMessage(
            `$${product.unitPrice} — ${product.description}`
          )}
          name={product.sku}
          placeholder={messages.quantityPlaceholder}
          min={0}
          max={1000}
        />
      </Box>
    );
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {productFields}
      <SubmitCancelButtonGroup onCancel={handleCancel} />
    </Form>
  );
}

function PhotoProducts({ appointment, order, items, photo }) {
  const productsResponse = useAppointmentPhotoProducts(
    appointment.sessionUuid,
    photo.filename
  );

  if (!productsResponse.json) {
    return <Loading />;
  }

  if (productsResponse.status !== 200) {
    return <Text>Not found</Text>;
  }

  return (
    <>
      <Heading textAlign="center">
        <FormattedMessage id="order.add-this-picture" />
      </Heading>
      <Text>
        Prints 8 × 10” and smaller are available in sheets, and the more you
        add, the more you save — select any combination of 1–2 sheets for $10
        each, 3–5 sheets for $8 each, and 6 or more sheets at just $6 each for
        your entire order! Enlargements and fun stuff are also available and are
        priced individually.
      </Text>
      <Flex>
        <Box
          width={{ base: "250px", sm: "800px" }}
          height={{ base: "300px", sm: "800px" }}
          mr="5"
          position="sticky"
          top="1em"
        >
          {photo.title && <Text textAlign="center">{photo.title}</Text>}
          <Box
            backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
              appointment.appointmentUuid
            )}/${encodeURIComponent(photo.filename)}`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="50% top"
            height={{ base: "250px", sm: "800px" }}
            maxHeight="90vh"
          />
        </Box>
        <AddPictureForm
          appointmentUuid={appointment.appointmentUuid}
          products={productsResponse.json.products}
          order={order}
          items={items}
          photoFilename={photo.filename}
        />
      </Flex>
    </>
  );
}

function Filename() {
  usePageTitle(messages.title);

  const { appointmentUuid, orderNumber, filename } = useParams();

  const appointmentResponse = useAppointment(appointmentUuid);
  const photosResponse = useAppointmentPhotos(appointmentUuid);
  const orderResponse = useOrder(appointmentUuid, orderNumber);

  if (
    !appointmentResponse.json ||
    !photosResponse.json ||
    !orderResponse.json
  ) {
    return <Loading />;
  }

  if (
    appointmentResponse.status !== 200 ||
    photosResponse.status !== 200 ||
    orderResponse.status !== 200
  ) {
    return <Text>Not found</Text>;
  }

  const photo = photosResponse.json.photos.find(
    (photo) => photo.filename === filename
  );
  if (!photo) {
    return <Text>Not found</Text>;
  }

  return (
    <PhotoProducts
      appointment={appointmentResponse.json.appointment}
      order={orderResponse.json.order}
      items={orderResponse.json.items}
      photo={photo}
    />
  );
}

export default Filename;
