import { addMethod, string } from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { string as locale } from "./locale.js";

addMethod(string, "phoneNumber", function (message = locale.phoneNumber) {
  return this.test({
    name: "phoneNumber",
    exclusive: true,
    message,
    test: function (value) {
      return value == null || value === "" || isValidPhoneNumber(value);
    },
  });
});
